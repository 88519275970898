import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import Routes from '../routes/Routes';
import { getGATrackingCode } from 'helpers/utils';

const ThemeProvider = () => {
  // Set default theme initially
  const theme = useSelector(({ theme }) => theme.config);

  const [measurementId, setMeasurementId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGATrackingCode()).then((response) => {
      const data = response.data;
      if (data?.statusCode == '200') {
        const { measurementId: gaTrackingCode } = data.result;
        setMeasurementId(gaTrackingCode);
      }
    });
  }, []);

  useEffect(() => {
    if (measurementId) {
      ReactGA.initialize(measurementId, {
        gaOptions: {
          siteSpeedSampleRate: 100,
        },
      });

      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      });
    }
  }, [measurementId]);

  return (
    <ConfigProvider theme={theme}>
      <Routes />
    </ConfigProvider>
  );
};

export default ThemeProvider;
