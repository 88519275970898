import Cookies from 'js-cookie';
import { getUUID } from '../../../../helpers/utils';
import { message, Modal, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const FETCH_RTF_HEADER_DETAILS =
  'pc/header-details/FETCH_RTF_HEADER_DETAILS';
export const FETCH_RTF_HEADER_DETAILS_SUCCESS =
  'pc/header-details/FETCH_RTF_HEADER_DETAILS_SUCCESS';
export const FETCH_RTF_HEADER_DETAILS_FAILURE =
  'pc/header-details/FETCH_RTF_HEADER_DETAILS_FAILURE';

export const UPDATE_HEADER_DETAILS = 'pc/header-details/UPDATE_HEADER_DETAILS';

const initialState = {
  loading: false,
  rtfHeaderDetailsData: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_HEADER_DETAILS:
      return {
        ...state,
        submittingItem: true,
      };
    case FETCH_RTF_HEADER_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RTF_HEADER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        rtfHeaderDetailsData: action.payload,
      };
    case FETCH_RTF_HEADER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

function getRtfHeaderDetailsData(param) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: FETCH_RTF_HEADER_DETAILS });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'FETCH_RTF_FIRM_HEADER_DETAILS',
            details: {
              email: Cookies.get('email'),
              customercode: param.customercode,
              customerplant: param.customerplant,
              partname: param.partname,
              supplierkey: param.supplierkey,
            },
            uuid: getUUID(),
            source: 'PORTAL',
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        let updateData = data.result.header_details;
        updateData = {
          ...updateData,
          customer: param.customercode,
        };

        data.result.header_details = updateData;

        if (data.result.header_details) {
          dispatch({
            type: FETCH_RTF_HEADER_DETAILS_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: FETCH_RTF_HEADER_DETAILS_FAILURE,
            payload: 'Error in fetching rtf header details popup data',
          });
        }

        return data;
      });
  };
}

export function updateHeaderDetails(formattedValues) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: UPDATE_HEADER_DETAILS });
    let headerDetails = [];
    headerDetails.push({
      customer_plant: formattedValues.customer_plant,
      part: formattedValues.part,
      supplier_key: formattedValues.supplier_key,
      comment: formattedValues.partcomments,
    });
    return api
      .post('pcsubmitaction', {
        TXN: {
          Header: {
            txn_type: 'COMMENT_UPDATE',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookies.get('email'),
            source: 'PORTAL',
          },
          PartsArray: headerDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET[0].return_msg === 'SUCCESS') {
          message.success('Header Details Data saved successfully');
        } else {
          const failureResponse = data.TXN[0].PartsArray;
          if (failureResponse == undefined || failureResponse.length == 0)
            message.error('Something went wrong. Please try again.');
          else {
            Modal.error({
              className: 'ccut-text',
              title: 'Update Fails!!',
              icon: <ExclamationCircleOutlined />,
              content: (
                <div
                  style={{
                    maxHeight: 250,
                    minHeight: 150,
                    overflowY: 'auto',
                  }}>
                  <div
                    style={{
                      borderBottom: '1px solid #CCC',
                      padding: 5,
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}>
                    <Row>
                      <Col style={{ textAlign: 'center' }} span={3} order={1}>
                        Plant
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={6} order={2}>
                        Supplier
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={7} order={3}>
                        Part
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={8} order={4}>
                        Error
                      </Col>
                    </Row>
                  </div>
                  {failureResponse[0].map((item) => (
                    <div
                      key={getUUID()}
                      style={{
                        borderBottom: '1px solid #CCC',
                        padding: 5,
                        fontSize: 10,
                      }}>
                      <Row>
                        <Col style={{ textAlign: 'center' }} span={3} order={1}>
                          {item.customer_plant}
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={6} order={2}>
                          {item.supplier_key}
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={7} order={3}>
                          {item.part}
                        </Col>
                        <Col
                          style={{
                            textAlign: 'center',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                          span={8}
                          order={4}>
                          <span title={item.error_message}>
                            {item.error_msg}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              ),
            });
          }
        }
      });
  };
}

export { getRtfHeaderDetailsData };
