import React, { useState, useEffect } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const CustomSplitRowDetailRenderer = (props) => {
  const [expanded, setExpanded] = useState(false);
  const splitCount = props.node.data.splitRecords.length;

  const handleExpand = (expand) => {
    props.api.forEachNode((node) => node.setExpanded(false));
    setTimeout(function () {
      props.node.setExpanded(expand);
    }, 0);
    setTimeout(function () {
      setExpanded(expand);
    }, 0);
    props.api.refreshCells({ force: true });
  };

  useEffect(() => {
    if (props.node.expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [props.node.expanded]);

  return (
    <>
      {(() => {
        if (splitCount) {
          if (expanded) {
            return (
              <MinusOutlined
                onClick={() => handleExpand(false)}
                style={{ paddingLeft: 3, color: '#0984e3', fontWeight: 'bold' }}
              />
            );
          } else {
            return (
              <PlusOutlined
                onClick={() => handleExpand(true)}
                style={{ paddingLeft: 3, color: '#0984e3', fontWeight: 'bold' }}
              />
            );
          }
        } else {
          return null;
        }
      })()}
    </>
  );
};

CustomSplitRowDetailRenderer.propTypes = {
  node: PropTypes.object,
  api: PropTypes.object,
};

export default CustomSplitRowDetailRenderer;
