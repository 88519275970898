import { getLastRowIndex, getUUID } from '../../../../../helpers/utils';
import service from '../../../../../service/Service';
import store from '../../../../../redux/store';
import Cookie from 'js-cookie';
import { message } from 'antd';

//below 3 const is for fetching OM userlist columns
const OM_USER_LIST_COLUMNS = 'ums/om/store/OM_USER_LIST_COLUMNS';
const OM_USER_LIST_COLUMNS_SUCCESS =
  'ums/om/store/OM_USER_LIST_COLUMNS_SUCCESS';
const OM_USER_LIST_COLUMNS_FAIL = 'ums/om/store/OM_USER_LIST_COLUMNS_FAIL';

//below 3 const is for fetching OM userlist rows
const OM_USER_LIST_ROWS_INFO_SUCCESS =
  'ums/om/store/OM_USER_LIST_ROWS_INFO_SUCCESS';

//below 3 const is for OM add new user
const ADD_OM_USER = 'ums/om/store/ADD_OM_USER';
const ADD_OM_USER_SUCCESS = 'ums/om/store/ADD_OM_USER_SUCCESS';
const ADD_OM_USER_FAIL = 'ums/om/store/ADD_OM_USER_FAIL';

//below 2 const is for OM edit existing user details
const UPDATE_OM_USER = 'ums/om/store/UPDATE_OM_USER';
const UPDATE_OM_USER_SUCCESS = 'ums/om/store/UPDATE_OM_USER_SUCCESS';
const UPDATE_OM_USER_FAIL = 'ums/om/store/UPDATE_OM_USER_FAIL';

//below 2 const is for OM edit existing user details
const ADD_DEPOT_DETAILS = 'ums/om/store/ADD_DEPOT_DETAILS';
const ADD_DEPOT_DETAILS_SUCCESS = 'ums/om/store/ADD_DEPOT_DETAILS_SUCCESS';

//below 2 const is for OM delete existing user details
const DELETE_DEPOT_DETAILS = 'ums/om/store/DELETE_DEPOT_DETAILS';
const DELETE_DEPOT_DETAILS_SUCCESS =
  'ums/om/store/DELETE_DEPOT_DETAILS_SUCCESS';

const ONCHANGE_DEPOT_DETAILS = 'ums/om/store/ONCHANGE_DEPOT_DETAILS';
const ONCHANGE_DEPOT_DETAILS_SUCCESS =
  'ums/om/store/ONCHANGE_DEPOT_DETAILS_SUCCESS';
const GET_SUPPLYDEPOT_LIST_SUCCESS =
  'ums/om/store/GET_SUPPLYDEPOT_LIST_SUCCESS';
const GET_SUPPLYDEPOT_LIST = 'ums/om/store/GET_SUPPLYDEPOT_LIST';

//below 2 const is for fetching OM specific existing user details
const GET_SPECIFIC_OM_USER_DETAIL = 'ums/om/store/GET_SPECIFIC_OM_USER_DETAIL';
const GET_SPECIFIC_OM_USER_DETAIL_SUCCESS =
  'ums/om/store/GET_SPECIFIC_OM_USER_DETAIL_SUCCESS';

//below 3 const is for fetching dropdown data while creating OM new user
const ADD_USER_DATA_LOADING = 'ums/om/store/ADD_USER_DATA_LOADING';
const ADD_USER_DATA_SUCCESS = 'ums/om/store/ADD_USER_DATA_SUCCESS';
const ADD_USER_DATA_FAIL = 'ums/om/store/ADD_USER_DATA_FAIL';

const initialState = {
  isLoading: false,
  omCreateUserDropdownDataLoaded: false,
  omCreateUserDropdownDataLoading: false,
  omCreateUserDropdownData: [],
  addUserMsg: '',
  addDepotDetails: [],
  deleteDepotDetails: [],
  userName: '',
  userDetails: [],
  supplier_depot: {},
  updateUserMsg: '',
  updatingUserState: false,
  storeColumns: {},
  rowsInfo: {
    endRow: 0,
    lastRow: 0,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_USER_DATA_LOADING:
      return { ...state, omCreateUserDropdownDataLoaded: true };
    case ADD_USER_DATA_SUCCESS:
      return {
        ...state,
        omCreateUserDropdownDataLoaded: true,
        omCreateUserDropdownDataLoading: false,
        omCreateUserDropdownData: action.payload,
        error: null,
      };
    case ADD_USER_DATA_FAIL:
      return {
        ...state,
        omCreateUserDropdownDataLoading: false,
        error: action.payload,
      };

    case ADD_OM_USER:
      return { ...state, isLoading: true };
    case ADD_OM_USER_SUCCESS:
      return { ...state, addUserMsg: action.data, isLoading: false };
    case ADD_OM_USER_FAIL:
    case UPDATE_OM_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_OM_USER:
      return { ...state, isLoading: true };
    case UPDATE_OM_USER_SUCCESS:
      return { ...state, updateUserMsg: 'SUCCESS', isLoading: false };
    case GET_SPECIFIC_OM_USER_DETAIL:
      return { ...state, isLoading: true };
    case GET_SPECIFIC_OM_USER_DETAIL_SUCCESS:
      return { ...state, userDetails: action.data, isLoading: false };
    case OM_USER_LIST_COLUMNS:
      return { ...state, isLoading: true };
    case OM_USER_LIST_COLUMNS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        storeColumns: action.payload,
        error: null,
      };
    case OM_USER_LIST_COLUMNS_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case OM_USER_LIST_ROWS_INFO_SUCCESS:
      return { ...state, rowsInfo: action.payload };
    case ADD_DEPOT_DETAILS:
      return { ...state };
    case ADD_DEPOT_DETAILS_SUCCESS:
      return { ...state, addDepotDetails: action.data };
    case DELETE_DEPOT_DETAILS:
      return { ...state };
    case DELETE_DEPOT_DETAILS_SUCCESS:
      return { ...state, deleteDepotDetails: action.data };
    case GET_SUPPLYDEPOT_LIST:
      return { ...state };
    case GET_SUPPLYDEPOT_LIST_SUCCESS:
      return {
        ...state,
        supplier_depot: action.data,
        error: null,
      };
    default:
      return state;
  }
}

export function getColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: OM_USER_LIST_COLUMNS });
    return api
      .post(
        'ums',
        formatParams({ type: 'AG_GRID_COLUMNS_PC', view: 'OM' }, getState)
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          dispatch({ type: OM_USER_LIST_COLUMNS_SUCCESS, payload: data });
        } else {
          dispatch({
            type: OM_USER_LIST_COLUMNS_FAIL,
            payload: 'Something went wrong! Please try again.',
          });
        }
      });
  };
}

export function getRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'AG_GRID_ROWS_PC',
              view: 'OM',
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (Object.keys(data).length) {
            params.success({
              rowData: data.rowData,
              rowCount: getLastRowIndex(params.request, data.rowData),
            });
            const endRow =
              params.request.endRow > data.lastRow
                ? data.lastRow
                : params.request.endRow;
            store.dispatch({
              type: OM_USER_LIST_ROWS_INFO_SUCCESS,
              payload: { lastRow: data.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

//below function is for fetch plant field dropdown details before add a new user screen loads
export function fetchDropdownData() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: ADD_USER_DATA_LOADING });
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'OM_DROPDOWN_DATA',
            },
            getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            dispatch({
              type: ADD_USER_DATA_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: ADD_USER_DATA_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return response;
        });
    }
  };
}

//below function is for calling api service and handling response for creating a new user
export function add(formattedValues) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: ADD_OM_USER });
    let userDetails = [];
    let getModule = formattedValues.module;
    userDetails.push({
      user_email: formattedValues.email,
      first_name: formattedValues.first_name,
      last_name: formattedValues.last_name,
      user_name: formattedValues.user_name,
      description: formattedValues.description,
      country: formattedValues.country,
      country_code: formattedValues.country_code,
      language: formattedValues.language,
      mobile: formattedValues.mobile == undefined ? '' : formattedValues.mobile,
      module: getModule.join(),
    });

    return api
      .post('ums', {
        TXN: {
          Header: {
            txn_type: 'PC_ROLES',
            type: 'ADD',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          UserFormArray: userDetails,
        },
      })
      .then((response) => {
        if (response.returnMsg === 'SUCCESS' && response.statusCode == '200') {
          dispatch(update(response));
        } else {
          dispatch({
            type: ADD_OM_USER_FAIL,
            payload: 'Error in fetching data',
          });
        }
        return response;
      });
  };
  function update(data) {
    return { type: ADD_OM_USER_SUCCESS, data };
  }
}

//below function is for calling api service and handling response for fetching details of a specific user
export function getUserDetails(email) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: GET_SPECIFIC_OM_USER_DETAIL });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'VIEW_SPECIFIC_USER_OM',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        if (response) {
          const data = response.data;
          if (data) {
            dispatch(update(data));
          }
        }
        return response;
      });
  };
  function update(data) {
    return { type: GET_SPECIFIC_OM_USER_DETAIL_SUCCESS, data };
  }
}

//below function is for calling api service and handling response for updating an existing user
export function updateUserDetails(formattedValues) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: UPDATE_OM_USER });
    let userDetails = [];
    userDetails.push({
      user_email: formattedValues.email_edit,
      user_name: formattedValues.user_name_edit,
      description: formattedValues.description_edit,
      module: 'OM',
    });

    if (formattedValues.userDetailsArray.length > 0)
      userDetails[0].UserDetailsArray = formattedValues.userDetailsArray;

    return api
      .post('ums', {
        TXN: {
          Header: {
            txn_type: 'PC_ROLES',
            type: 'EDIT',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          UserFormArray: userDetails,
        },
      })
      .then((response) => {
        if (response.returnMsg === 'SUCCESS' && response.statusCode == '200') {
          dispatch(update());
        } else {
          dispatch({
            type: UPDATE_OM_USER_FAIL,
            payload: 'Error in updating user',
          });
        }
        return response;
      });
  };
  function update() {
    return { type: UPDATE_OM_USER_SUCCESS };
  }
}

//below function is for calling api service and handling response for deleting an existing user
export function deleteUser(formattedValues) {
  return (_dispatch, _getState, { api }) => {
    let userDetails = [];
    let getModule = formattedValues[0].module;

    userDetails.push({
      user_email: formattedValues[0].user_email,
      user_name: formattedValues[0].user_name,
      description: formattedValues[0].description,
      module: getModule,
    });

    return api
      .post('ums', {
        TXN: {
          Header: {
            txn_type: 'PC_ROLES',
            type: 'DELETE',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          UserFormArray: userDetails,
        },
      })
      .then((response) => {
        return response;
      });
  };
}

export function copyUserDetailsToAnotherModule(formattedValues) {
  return (_dispatch, _getState, { api }) => {
    let userFormDetails = [];
    let getModule = formattedValues.userDetails.module;
    let getcountry = formattedValues.userDetails.country;
    let getlanguage = formattedValues.userDetails.language;

    const countryList =
      store.getState().ums.om.omCreateUserDropdownData.countryList;
    let data = getcountry;
    let ccode = '';
    if (getcountry != undefined && getcountry != '') {
      countryList.map((countries) => {
        if (countries.name == data) ccode = countries.dial_code;
      });
    }
    let countryCode = ccode;

    userFormDetails.push({
      user_email: formattedValues.userDetails.email,
      first_name: formattedValues.userDetails.first_name,
      last_name: formattedValues.userDetails.last_name,
      user_name: formattedValues.userDetails.user_name,
      description:
        formattedValues.userDetails.description == undefined
          ? ''
          : formattedValues.userDetails.description,
      country: getcountry,
      language: getlanguage.join(),
      mobile:
        formattedValues.userDetails.mobile != undefined &&
        formattedValues.userDetails.mobile != ''
          ? countryCode + formattedValues.userDetails.mobile
          : '',
      module: getModule.join(),
    });

    let userDetailsArr = [];
    let getmoduleselected = formattedValues.modules;
    userDetailsArr.push({
      email: formattedValues.email,
      module_copyfrom: 'OM',
      module_copyto: getmoduleselected,
      action: 'clone_module',
    });

    userFormDetails[0].UserDetailsArray = userDetailsArr;

    return api
      .post('ums', {
        TXN: {
          Header: {
            txn_type: 'PC_ROLES',
            type: 'COPY_MODULE',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
          },
          UserFormArray: userFormDetails,
        },
      })
      .then((response) => {
        return response;
      });
  };
}

export function copyUserRoleDetailsToAnotherUser(formattedValues) {
  return (_dispatch, _getState, { api }) => {
    let userFormDetails = [];
    let getModule = formattedValues.userDetails.module;
    let getcountry = formattedValues.userDetails.country;
    let getlanguage = formattedValues.userDetails.language;

    const countryList =
      store.getState().ums.om.omCreateUserDropdownData.countryList;
    let data = getcountry;
    let ccode = '';
    if (getcountry != undefined && getcountry != '') {
      countryList.map((countries) => {
        if (countries.name == data) ccode = countries.dial_code;
      });
    }
    let countryCode = ccode;

    userFormDetails.push({
      user_email: formattedValues.userDetails.email,
      first_name: formattedValues.userDetails.first_name,
      last_name: formattedValues.userDetails.last_name,
      user_name: formattedValues.userDetails.user_name,
      description:
        formattedValues.userDetails.description == undefined
          ? ''
          : formattedValues.userDetails.description,
      country: getcountry,
      language: getlanguage.join(),
      mobile:
        formattedValues.userDetails.mobile != undefined &&
        formattedValues.userDetails.mobile != ''
          ? countryCode + formattedValues.userDetails.mobile
          : '',
      module: getModule.join(),
      copyemail_from: formattedValues.email,
      copyemail_to: formattedValues.user_name,
      action: 'CLONE_USER',
    });

    userFormDetails[0].UserDetailsArray = formattedValues.selectedDepotDetails;

    return api
      .post('ums', {
        TXN: {
          Header: {
            txn_type: 'PC_ROLES',
            type: 'COPY',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
          },
          UserFormArray: userFormDetails,
        },
      })
      .then((response) => {
        return response;
      });
  };
}

export function depotDetailsAdd(formattedValues) {
  return (dispatch) => {
    dispatch({ type: ADD_DEPOT_DETAILS });
    dispatch(update(formattedValues));
    return true;
  };
  function update(data) {
    return { type: ADD_DEPOT_DETAILS_SUCCESS, data };
  }
}
export function depotDetailsDelete(formattedValues) {
  return (dispatch) => {
    dispatch({ type: DELETE_DEPOT_DETAILS });
    dispatch(update(formattedValues));
    return true;
  };
  function update(data) {
    return { type: DELETE_DEPOT_DETAILS_SUCCESS, data };
  }
}

export function emptyDepotList() {
  return (dispatch) => {
    let data = {
      supply_depot_list: [],
    };
    dispatch({ type: GET_SUPPLYDEPOT_LIST });
    dispatch(updateDepotList(data));
    return data;
  };

  function updateDepotList(data) {
    return { type: GET_SUPPLYDEPOT_LIST_SUCCESS, data };
  }
}

export function onChangedepotDetailsAdd(formattedValues) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: ONCHANGE_DEPOT_DETAILS });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'PC_SUPPLIER_DETAILS',
            details: formattedValues,
          },
          getState
        )
      )
      .then((response) => {
        if (response) {
          if (formattedValues.supplyDepot == '') {
            dispatch(updateDepotList(response.data));
          } else dispatch(update(response));
        } else {
          message.error('Something went wrong. Please try again!');
        }
        return response;
      });
  };
  function update(data) {
    return { type: ONCHANGE_DEPOT_DETAILS_SUCCESS, data };
  }

  function updateDepotList(data) {
    return { type: GET_SUPPLYDEPOT_LIST_SUCCESS, data };
  }
}
