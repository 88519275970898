import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getSiderOptions } from '../redux/modules/layout';
import {
  getUserDetails,
  profileDropdownData,
} from '../../profile/redux/modules/profile';
import Sider from './Sider';
import Handletour from 'modules/tour/handletour';
import PropTypes from 'prop-types';

const SiderContainer = (props) => {
  const menuOptions = useSelector(
    ({ layout }) => layout.menuOptions,
    shallowEqual
  );
  const { authorizationRequest, defaultAccess, menuSelected, isUserHasAccess } =
    useSelector(({ authorization, layout }) => ({
      authorizationRequest: authorization.loading,
      defaultAccess: authorization.initAuthorization.defaultAccess,
      menuSelected: layout.menuSelected,
      isUserHasAccess: authorization.isUserHasAccess,
    }));

  const dispatch = useDispatch();

  const { addTab } = props;

  useEffect(() => {
    if (isUserHasAccess) {
      dispatch(getSiderOptions(defaultAccess));
    }
    dispatch(getUserDetails());
    dispatch(profileDropdownData());
  }, [defaultAccess]);
  const handlePageClick = (menuName) =>
    dispatch({
      type: 'layout/SWITCH_CONTENT_LAYOUT_SUCCESS',
      payload: true,
      menuSelected: menuName,
    });

  return (
    <>
      <Handletour defaultAccess={defaultAccess} />
      <Sider
        {...props}
        authorizationRequest={authorizationRequest}
        menuOptions={menuOptions}
        handlePageClick={handlePageClick}
        addTab={addTab}
        defaultSelectedKeys={menuSelected}
      />
    </>
  );
};

SiderContainer.propTypes = {
  addTab: PropTypes.func,
};

export default SiderContainer;
