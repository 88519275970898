import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

const OverlayLoading = ({ description = 'One moment please...' }) => {
  return (
    <div
      className='ag-overlay-loading-center'
      style={{ border: 'none', fontSize: 12 }}
      data-testid='overlay-loading'>
      <Spin size='small' tip={description} />
    </div>
  );
};

OverlayLoading.propTypes = {
  description: PropTypes.string.isRequired,
};

export default OverlayLoading;
