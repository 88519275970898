import { message } from 'antd';
import Cookie from 'js-cookie';
import { getUUID } from 'helpers/utils';

const OM_SITE_EXEC_LIST = 'modules/pc/OM_SITE_EXEC_LIST';
const OM_SITE_EXEC_LIST_SUCCESS = 'moduels/pc/OM_SITE_EXEC_LIST_SUCCESS';
const OM_SITE_EXEC_LIST_FAIL = 'modules/pc/OM_SITE_EXEC_LIST_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = null) {
  switch (action.type) {
    case OM_SITE_EXEC_LIST:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: true,
          archive: [],
          purge: [],
        },
      };
    case OM_SITE_EXEC_LIST_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          ...action.payload,
        },
      };
    case OM_SITE_EXEC_LIST_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
          archive: [],
          purge: [],
        },
      };
    default:
      return state;
  }
}

function getTxnDetails(tabKey, plant) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: OM_SITE_EXEC_LIST, tabKey });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'PC_OM_EXEC_LIST',
            plant,
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode === '200') {
          dispatch({
            type: OM_SITE_EXEC_LIST_SUCCESS,
            payload: data.result,
            tabKey,
          });
        } else {
          dispatch({
            type: OM_SITE_EXEC_LIST_FAIL,
            payload: 'Something went wrong!',
            tabKey,
          });
        }
      })
      .catch((error) =>
        dispatch({ type: OM_SITE_EXEC_LIST_FAIL, payload: error, tabKey })
      );
  };
}

function commitTxn(type, plant, toggle, t) {
  const commitDetails = {
    TXN: {
      Header: {
        txn_type: type,
        customer: 'SANM',
        requestor: Cookie.get('email'),
        source: 'PORTAL',
        uuid: getUUID(),
      },
      PurgeToggleArray: {
        plant,
        toggle: toggle.toString().toUpperCase(),
      },
    },
  };
  return (_dispatch, _getState, { api }) => {
    return api.post('pcsubmitaction', commitDetails).then((response) => {
      const { data } = response;
      if (data) {
        if (data.TXN && data.TXN[0]['return_msg'] == 'SUCCESS') {
          message.success(t('request_submitted_successfully'));
        } else {
          message.error(t('something_went_wrong'));
        }
      } else {
        message.error(t('something_went_wrong'));
      }
    });
  };
}

export { getTxnDetails, commitTxn };
