import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { getInitAuthorization } from '../modules/layout/redux/modules/authorization';
import Loading from '../modules/layout/Loading';
import HelpDesk from '../modules/helpdesk/helpDesk';
import PropTypes from 'prop-types';

function HelpdeskRoute({ component: Component, ...rest }) {
  const authenticating = useSelector(
    ({ authentication }) => authentication.loggingIn
  );

  const dispatch = useDispatch();
  const accessToken = Cookies.get('accessToken');
  const continueSession = Cookies.get('continueSession');

  useEffect(() => {
    if (continueSession === 'true' && accessToken) {
      dispatch(getInitAuthorization());
    }
  }, [continueSession]);

  if (authenticating) {
    return <Loading />;
  }

  if (accessToken && continueSession === 'true') {
    return <Component {...rest} />;
  }

  return <HelpDesk />;
}

HelpdeskRoute.propTypes = {
  component: PropTypes.any,
};

export default HelpdeskRoute;
