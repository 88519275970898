import React, { createRef, memo } from 'react';
import { DatePicker, Space, Popover } from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import PropTypes from 'prop-types';

dayjs.extend(weekday);
dayjs.extend(localeData);

const DatePickerComponent = memo((props) => (
  <DatePicker
    onChange={props.handleChange}
    value={props.date ? dayjs(props.date) : ''}
    ref={props.inputRef}
    className={props.validationStatus}
    size='small'
    disabledDate={props.getDisabledDates}
    defaultPickerValue={props.date ? dayjs(props.date) : dayjs()}
  />
));

DatePickerComponent.propTypes = {
  handleChange: PropTypes.func,
  date: PropTypes.any,
  inputRef: PropTypes.any,
  validationStatus: PropTypes.string,
  getDisabledDates: PropTypes.func,
};

class Datepicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: dayjs(),
      validationStatus: 'success',
      errorDesc: '',
      popoverVisibility: false,
    };
    this.inputRef = createRef();
    this.isNodeSelected = props.node.isSelected(); // NOSONAR
    this.nodeDataInitial = props.node.data;
    this.colId = props.column.getColId();
  }

  componentDidMount() {
    const errors = this.props.node.data.errors;
    if (errors && errors[this.colId] !== undefined) {
      this.setState({
        validationStatus: 'error',
        errorDesc: this.props.node.data.errors[this.colId],
      });
    }
    this.setState({ date: this.props.value });
  }

  componentDidUpdate(_prevProps, prevState) {
    const errors = this.props.node.data.errors;
    if (errors && errors[this.colId] !== undefined) {
      if (prevState.errorDesc !== this.props.node.data.errors[this.colId]) {
        this.setState({
          validationStatus: 'error',
          errorDesc: this.props.node.data.errors[this.colId],
        });
      }
    }
  }

  // prettier-ignore
  getValue() { // NOSONAR
    return this.props.node.data.isEditing
      ? this.state.date
      : this.nodeDataInitial[this.props.column.getColId()];
  }

  handleChange = (date) => {
    let value = '';
    if (date) value = date.format('YYYY-MM-DD');
    this.setState({ date: value });
    if (!this.props.node.isSelected()) {
      this.props.node.setSelected(true);
      this.props.api.refreshCells({
        force: true,
        columns: ['select', 'sub-check'],
      });
      this.props.api.refreshHeader();
    }
    this.props.node.setDataValue(this.colId, value);
  };

  getDisabledDates = (current) => {
    if (this.props.disablePastDates) {
      return dayjs().add(-1, 'day') >= current;
    } else if (this.props.disableHolidays) {
      //To disable past dates and weekends in OM
      return (
        dayjs().day() === 0 ||
        dayjs().day() === 6 ||
        dayjs().add(-1, 'day') >= current
      );
    }
    return false;
  };

  handlePopoverVisibility = () => {
    if (this.state.errorDesc !== '') {
      this.setState({ popoverVisibility: true });
    }
  };

  getErrorDesc = () => (
    <div style={{ fontSize: 12 }}>{this.state.errorDesc}</div>
  );

  render() {
    return (
      <Popover
        content={() => this.getErrorDesc()}
        open={this.state.popoverVisibility}
        title={null}
        placement='top'>
        <Space
          direction='vertical'
          onMouseOver={this.handlePopoverVisibility}
          onMouseOut={() => this.setState({ popoverVisibility: false })}>
          <DatePickerComponent
            date={this.state.date}
            handleChange={this.handleChange}
            inputRef={this.inputRef}
            validationStatus={this.state.validationStatus}
            getDisabledDates={this.getDisabledDates}
          />
        </Space>
      </Popover>
    );
  }
}

Datepicker.propTypes = {
  node: PropTypes.object,
  column: PropTypes.object,
  api: PropTypes.object,
  value: PropTypes.any,
  disableHolidays: PropTypes.bool,
  disablePastDates: PropTypes.bool,
};

export default Datepicker;
