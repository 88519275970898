import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(_error, _info) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      //Custom fallback UI
      return (
        <div
          className='error-boundary'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: 100,
          }}>
          <div
            style={{
              fontSize: 18,
              color: '#666666',
              margin: 0,
            }}>
            <ExclamationCircleOutlined />
          </div>
          <div style={{ margin: 0 }}>Oops! Something went wrong.</div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element,
};

export default ErrorBoundary;
