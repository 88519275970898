import React, { useState, useEffect } from 'react';
import { Checkbox, Tooltip } from 'antd';
import { getUUID } from 'helpers/utils';
import PropTypes from 'prop-types';

const CustomRowCheckboxRenderer = (props) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    let nodeSelected = props.node.isSelected();

    if (nodeSelected) {
      setSelected(true);
    } else {
      setSelected(false);
    }

    props.api.refreshCells({ force: true, columns: ['id'] });
  }, [props.node.isSelected()]);

  const removeErrors = () => {
    if (
      props.node.data.errors &&
      Object.getOwnPropertyNames(props.node.data.errors).length > 0
    ) {
      props.node.updateData({
        ...props.node.data,
        errors: {},
      });
      props.api.refreshCells({ force: true });
    }
  };

  const handleClick = (event) => {
    setSelected(event.target.checked);
    props.node.setSelected(event.target.checked);

    if (!event.target.checked) {
      // Remove all errors in the node if unchecked
      removeErrors();
    }

    props.api.refreshHeader();
    props.api.refreshCells({ force: true });
  };

  const getErrorDetails = () => {
    const errors = props.node.data.errors;
    if (errors && errors !== undefined) {
      let consolErrLength = Object.getOwnPropertyNames(
        props.node.data.errors
      ).length;
      let errorsConsolidated = [];
      if (consolErrLength > 0) {
        for (const property in props.node.data.errors) {
          if (props.node.data.errors[property].length) {
            errorsConsolidated.push(props.node.data.errors[property]);
          }
        }
      }

      if (errorsConsolidated.length) {
        return errorsConsolidated;
      }
    }

    return [];
  };

  const getErrorContent = () => {
    const errorsConsolidated = getErrorDetails();
    if (errorsConsolidated.length) {
      return (
        <>
          {errorsConsolidated.map((data) => {
            return (
              <div style={{ fontSize: 10 }} key={getUUID()}>
                {typeof data === 'string'
                  ? data
                  : data.map((d) => <div key={getUUID()}>{d}</div>)}
              </div>
            );
          })}
        </>
      );
    }

    return null;
  };

  if (props.data.editable) {
    return (
      <Tooltip
        title={getErrorDetails().length ? getErrorContent() : ''}
        placement='rightBottom'>
        <span className={getErrorDetails().length ? 'om-row-has-error' : null}>
          <Checkbox
            checked={selected}
            defaultChecked={false}
            onClick={handleClick}
          />
        </span>
      </Tooltip>
    );
  } else {
    return null;
  }
};

CustomRowCheckboxRenderer.propTypes = {
  node: PropTypes.object,
  api: PropTypes.object,
  data: PropTypes.any,
};

export default CustomRowCheckboxRenderer;
