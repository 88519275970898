import { message } from 'antd';
import { getLastRowIndex } from '../../../../helpers/utils';
import service from '../../../../service/Service';
import store from '../../../../redux/store';
import Cookie from 'js-cookie';
import { getUUID } from 'helpers/utils';

const AMSADD_USER_UPDATE = 'amsadd/store/AMSADD_USER_UPDATE';
const AMSADD_USER_UPDATE_SUCCESS = 'amsadd/store/AMSADD_USER_UPDATE_SUCCESS';
const AMSADD_USER_DETAIL = 'amsadd/store/AMSADD_USER_DETAIL';
const AMSADD_USER_DETAIL_SUCCESS = 'amsadd/store/AMSADD_USER_DETAIL_SUCCESS';
const ADD_AMSADD_USER = 'amsadd/store/ADD_AMSADD_USER';
const ADD_AMSADD_USER_SUCCESS = 'amsadd/store/ADD_AMSADD_USER_SUCCESS';
const ADD_AMSADD_USER_FAIL = 'amsadd/store/ADD_AMSADD_USER_FAIL';
const AMSADD_USER_LIST_COLUMNS = 'amsadd/store/AMSADD_USER_LIST_COLUMNS';
const AMSADD_USER_LIST_COLUMNS_SUCCESS =
  'ums/amsadd/store/AMSADD_USER_LIST_COLUMNS_SUCCESS';
const UMSADD_USER_LIST_COLUMNS_FAIL =
  'ums/amsadd/UMSADD_USER_LIST_COLUMNS_FAIL';
const AMSADD_USER_LIST_ROWS_INFO_SUCCESS =
  'ams/store/AMSADD_USER_LIST_ROWS_INFO_SUCCESS';
const GET_AMSADD_LOADING = 'amsadd/store/GET_AMSADD_LOADING';
const PLANTS_FOR_REGIONS_DATA_SUCCESS =
  'amsadd/store/PLANTS_FOR_REGIONS_DATA_SUCCESS';
const PLANTS_FOR_REGIONS_DATA_FAIL =
  'amsadd/store/PLANTS_FOR_REGIONS_DATA_FAIL';
const AMSADD_CREATE_USER_DROPDOWN_DATA_LOADING =
  'amsadd/store/AMSADD_CREATE_USER_DROPDOWN_DATA_LOADING';
const AMSADD_CREATE_USER_DROPDOWN_DATA_SUCCESS =
  'amsadd/store/AMSADD_CREATE_USER_DROPDOWN_DATA_SUCCESS';
const AMSADD_CREATE_USER_DROPDOWN_DATA_FAIL =
  'amsadd/store/AMSADD_CREATE_USER_DROPDOWN_DATA_FAIL';

const initialState = {
  isLoading: false,
  amsCreateUserDropdownDataLoaded: false,
  amsCreateUserDropdownDataLoading: false,
  amsCreateUserDropdownData: [],
  amsCreateUserDropdownPlantsDataLoaded: false,
  amsCreateUserDropdownPlantsDataLoading: false,
  amsCreateUserDropdownPlantsData: [],
  addUserMsg: '',
  userName: '',
  userDetails: [],
  updateUserMsg: '',
  updatingUserState: false,
  storeColumns: {},
  rowsInfo: {
    endRow: 0,
    lastRow: 0,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AMSADD_CREATE_USER_DROPDOWN_DATA_LOADING:
      return { ...state, amsCreateUserDropdownDataLoaded: true };
    case AMSADD_CREATE_USER_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        amsCreateUserDropdownDataLoaded: true,
        amsCreateUserDropdownDataLoading: false,
        amsCreateUserDropdownData: action.payload,
        error: null,
      };
    case AMSADD_CREATE_USER_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        amsCreateUserDropdownDataLoading: false,
        error: action.payload,
      };

    case ADD_AMSADD_USER:
      return { ...state, isLoading: true };
    case ADD_AMSADD_USER_SUCCESS:
      return { ...state, addUserMsg: action.data, isLoading: false };
    case ADD_AMSADD_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case AMSADD_USER_UPDATE:
      return { ...state, isLoading: true };
    case AMSADD_USER_UPDATE_SUCCESS:
      return { ...state, updateUserMsg: 'SUCCESS', isLoading: false };
    case AMSADD_USER_DETAIL:
      return { ...state, isLoading: true };
    case AMSADD_USER_DETAIL_SUCCESS:
      return { ...state, userDetails: action.data, isLoading: false };
    case AMSADD_USER_LIST_COLUMNS:
      return { ...state, isLoading: true };
    case AMSADD_USER_LIST_COLUMNS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        storeColumns: action.payload,
        error: null,
      };
    case UMSADD_USER_LIST_COLUMNS_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case AMSADD_USER_LIST_ROWS_INFO_SUCCESS:
      return { ...state, rowsInfo: action.payload };
    case GET_AMSADD_LOADING:
      return { ...state, createUserDropdownPlantsDataLoaded: false };
    case PLANTS_FOR_REGIONS_DATA_SUCCESS:
      return {
        ...state,
        amsCreateUserDropdownPlantsDataLoaded: true,
        amsCreateUserDropdownPlantsDataLoading: false,
        amsCreateUserDropdownPlantsData: action.payload,
        error: null,
      };
    default:
      return state;
  }
}

export function getColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: AMSADD_USER_LIST_COLUMNS });
    return api
      .post(
        'ums',
        formatParams({ type: 'AG_GRID_COLUMNS_AMS', view: 'AMSADD' }, getState)
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          if (data.columnDefs[8] && data.columnDefs[8].field == 'status') {
            data.columnDefs[8].cellRenderer = function (params) {
              if (
                params.value.url != '' &&
                (params.value.value == 'Pending AD Account' ||
                  params.value.value == 'Pending AD Account Removal')
              ) {
                return (
                  <a
                    style={{ color: 'blue' }}
                    href={params.value.url}
                    target='_blank'>
                    {params.value.value}
                  </a>
                );
              } else {
                return params.value.value;
              }
            };
          }
          dispatch({ type: AMSADD_USER_LIST_COLUMNS_SUCCESS, payload: data });
        } else {
          dispatch({
            type: UMSADD_USER_LIST_COLUMNS_FAIL,
            payload: 'Something went wrong! Please try again.',
          });
        }
      });
  };
}

export function getRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'AG_GRID_ROWS_AMS',
              view: 'AMSADD',
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (Object.keys(data).length) {
            params.success({
              rowData: data.rowData,
              rowCount: getLastRowIndex(params.request, data.rowData),
            });
            const endRow =
              params.request.endRow > data.lastRow
                ? data.lastRow
                : params.request.endRow;
            store.dispatch({
              type: AMSADD_USER_LIST_ROWS_INFO_SUCCESS,
              payload: { lastRow: data.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

export function fetchDropdownData() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: AMSADD_CREATE_USER_DROPDOWN_DATA_LOADING });
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'AMS_DROPDOWN_DATA',
            },
            getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            dispatch({
              type: AMSADD_CREATE_USER_DROPDOWN_DATA_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: AMSADD_CREATE_USER_DROPDOWN_DATA_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return response;
        });
    }
  };
}
export function fetchPlantData(regions) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: GET_AMSADD_LOADING });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'PLANTS_FOR_REGIONS_REQUEST',
            regions: regions,
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          dispatch({
            type: PLANTS_FOR_REGIONS_DATA_SUCCESS,
            payload: data,
          });
        }
        return response;
      });
  };
}
export function add(formattedValues) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: ADD_AMSADD_USER });
    let userDetails = [];
    let getRole = formattedValues.user_role;
    let getregion = formattedValues.region;
    let getplant = formattedValues.plant;
    userDetails.push({
      user_email: formattedValues.email,
      description: formattedValues.description,
      first_name: formattedValues.first_name,
      last_name: formattedValues.last_name,
      user_name: formattedValues.user_name,
      country: formattedValues.country,
      role: getRole.join(),
    });
    if (
      formattedValues.supplier_name != '' &&
      formattedValues.supplier_name !== undefined
    ) {
      userDetails[0].clean_supplier_name = formattedValues.supplier_name;
    }
    if (getregion != '' && getregion !== undefined) {
      userDetails[0].region = getregion.join();
    }
    if (getplant != '' && getplant !== undefined) {
      userDetails[0].plant = getplant.join();
    }

    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'ACP_USER',
            type: 'ADD',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          details: userDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET[0].return_msg === 'SUCCESS') {
          dispatch(update(data));
        } else {
          dispatch({
            type: ADD_AMSADD_USER_FAIL,
            payload: 'Error in fetching data',
          });
        }
        return TXNGET;
      });
  };
  function update(data) {
    return { type: ADD_AMSADD_USER_SUCCESS, data };
  }
}

export function getUserDetails(email) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: AMSADD_USER_DETAIL });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'VIEW_SPECIFIC_USER_AMS',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        if (response) {
          const data = response.data;
          if (data) {
            dispatch(update(data));
          }
        }
        return response;
      });
  };
  function update(data) {
    return { type: AMSADD_USER_DETAIL_SUCCESS, data };
  }
}

export function updateUserDetails(formattedValues) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: AMSADD_USER_UPDATE });
    let userDetails = [];
    let getRole = formattedValues.user_role_edit;
    let getregion = formattedValues.region_edit;
    let getplant = formattedValues.plant_edit;
    userDetails.push({
      user_email: formattedValues.email_edit,
      description: formattedValues.description_edit,
      first_name: formattedValues.first_name_edit,
      last_name: formattedValues.last_name_edit,
      user_name: formattedValues.user_name_edit,
      country: formattedValues.country_edit,
      role: getRole.join(),
      clean_supplier_name: '',
    });
    if (
      formattedValues.supplier_name_edit != '' &&
      formattedValues.supplier_name_edit !== undefined
    ) {
      userDetails[0].clean_supplier_name = formattedValues.supplier_name_edit;
    }
    if (getregion != '' && getregion !== undefined) {
      userDetails[0].region = getregion.join();
    }
    if (getplant != '' && getplant !== undefined) {
      userDetails[0].plant = getplant.join();
    }
    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'ACP_USER',
            type: 'EDIT',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          details: userDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET[0].return_msg === 'SUCCESS') {
          dispatch(update());
        } else {
          dispatch({
            type: ADD_AMSADD_USER_FAIL,
            payload: 'Error in updating user',
          });
        }
        return TXNGET[0].return_msg;
      });
  };
  function update() {
    return { type: AMSADD_USER_UPDATE_SUCCESS };
  }
}

export function deleteUser(email) {
  return (_dispatch, _getState, { api }) => {
    let userDetails = [];
    userDetails.push({
      user_email: email,
    });
    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'ACP_USER',
            type: 'DELETE',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          details: userDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET[0].return_msg === 'SUCCESS') {
          message.success('User deleted successfully');
        } else {
          message.error('Something went wrong. Please try again!');
        }
      });
  };
}
