import { combineReducers } from 'redux';

import dashboard from '../dashboard/redux/pc-dashboard';
import parts from '../parts/redux/parts';
import forecast from '../fc/redux/forecast';
import rtfFirmReleaseResponse from '../rtf/redux/rtf-firm-release-response';
import rtfReleaseHistory from '../rtf/redux/rtf-release-history';
import rtfReleaseReconciliation from '../rtf/redux/rtf-release-reconciliation';
import HeaderDetails from '../../common/pc_header_details/redux/pc-header-details';
import smi from '../smi/redux/smi';
import asnShipNotice from '../asn/redux/asn-ship-notice';
import asnHistory from '../asn/redux/asn-history';
import vci from '../vci/redux/vci';
import supplierInventory from '../supplier-inventory/redux/supplier-inventory';
import inventoryHistory from '../supplier-inventory/redux/supplier-inventory-history';
import newbuys from '../om/nbs/redux/newbuys';
import reschedules from '../om/reschedules/redux/reschedules';
import siteExecutor from '../site-executor/redux/site-executor';
import exportHistory from '../export-history/redux/export-history';
import search from '../search/redux/search';

const pcReducer = combineReducers({
  dashboard,
  parts,
  forecast,
  rtfFirmReleaseResponse,
  rtfReleaseHistory,
  rtfReleaseReconciliation,
  HeaderDetails,
  smi,
  asnShipNotice,
  asnHistory,
  vci,
  supplierInventory,
  inventoryHistory,
  newbuys,
  reschedules,
  siteExecutor,
  exportHistory,
  search,
});

export default pcReducer;
