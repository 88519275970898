import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import store from '../../../../redux/store';
import service from '../../../../service/Service';
import { getLastRowIndex, getUUID } from '../../../../helpers/utils';
import selectColumn from '../components/ag-grid-select-column';
import { message, Modal } from 'antd';
import Cookie from 'js-cookie';
import { exportDTo, getMassDownloadGridParams } from 'helpers/export';
export const PARTS_COLUMNS = 'parts/parts/PARTS_COLUMNS';
export const PARTS_COLUMNS_SUCCESS = 'parts/parts/PARTS_COLUMNS_SUCCESS';
export const PARTS_COLUMNS_FAIL = 'parts/parts/PARTS_COLUMNS_FAIL';
export const PARTS_ROWS_INFO = 'parts/parts/PARTS_ROWS_INFO';
export const PARTS_DATA_SUBMIT = 'pc/parts/PARTS_DATA_SUBMIT';
export const PARTS_DATA_SUBMIT_SUCCESS = 'pc/parts/PARTS_DATA_SUBMIT_SUCCESS';
export const PARTS_DATA_SUBMIT_FAIL = 'pc/parts/PARTS_DATA_SUBMIT_FAIL';
export const VIEW_PARTS_DETAIL_SUCCESS = 'pc/parts/VIEW_PARTS_DETAIL_SUCCESS';
export const VIEW_MRP_SUCCESS = 'pc/parts/VIEW_MRP_SUCCESS';
export const VIEW_MRP = 'pc/parts/VIEW_MRP';
export const PART_DETAIL = 'pc/parts/PART_DETAIL';
const COMMENTS_UPDATE = 'pc/parts/COMMENTS_UPDATE';
const COMMENTS_UPDATE_SUCCESS = 'pc/parts/COMMENTS_UPDATE_SUCCESS';
const COMMENTS_UPDATE_FAIL = 'pc/parts/COMMENTS_UPDATE_FAIL';

const initialState = {
  newTab0: {
    isLoading: false,
    loading: false,
    mrpLoading: true,
    rowsInfo: {
      endRow: 0,
      lastRow: 0,
    },
    partsFormColumns: {},
    headerDetails: [],
    mrpDetails: [],
  },
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PARTS_COLUMNS:
    case COMMENTS_UPDATE:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loading: true },
      };
    case PARTS_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          rowsInfo: action.payload,
        },
      };
    case PARTS_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          partsFormColumns: action.payload,
          loaded: true,
        },
      };
    case PARTS_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
          loaded: true,
        },
      };
    case VIEW_PARTS_DETAIL_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          headerDetails: action.data,
        },
      };
    case VIEW_MRP:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], mrpLoading: true },
      };
    case VIEW_MRP_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          mrpDetails: {
            ...state[action.tabKey].mrpDetails,
            [action.part]: { mrpLoading: false, data: action.data },
          },
        },
      };
    case COMMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loading: false },
      };
    case COMMENTS_UPDATE_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
        },
      };
    case PARTS_DATA_SUBMIT:
      return { ...state, submittingItem: true };
    case PARTS_DATA_SUBMIT_SUCCESS:
      return { ...state, submittingItem: false, submittedItem: true };
    case PARTS_DATA_SUBMIT_FAIL:
      return { ...state, submittingItem: false };
    default:
      return state;
  }
}

function getpartsFormColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PARTS_COLUMNS, tabKey });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `PARTS`,
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200') {
          let configs = data.result;
          configs.columnDefs.unshift({
            ...selectColumn,
          });

          dispatch({
            type: PARTS_COLUMNS_SUCCESS,
            payload: configs,
            tabKey,
          });
        } else {
          dispatch({
            type: PARTS_COLUMNS_FAIL,
            payload: 'Error in loading fc form!',
            tabKey,
          });
        }
      });
  };
}
const getQsProps = (tabKey) => {
  const qsState = store.getState().pc.search.quickSearch;
  const tabData = qsState[tabKey] || {};

  const stringify = (val = '') => {
    if (typeof val !== 'string') {
      const filteredVal = val.filter((data) => data !== 'all');
      return filteredVal.join();
    } else {
      return val;
    }
  };

  return {
    customer_plant: stringify(tabData.customerPlant),
    supplier_name: stringify(tabData.supplierName),
    supplier_code: stringify(tabData.supplierCode),
    supplier_key: stringify(tabData.supplierDepot),
    agility_type: stringify(tabData.agilityType),
    site_name:stringify(tabData.supplierSite),
    search_term: tabData.searchTerm,
  };
};
function getpartsFormRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: `PARTS`,
              gridParams: { ...params.request },
              details: {
                email: Cookie.get('email'),
                ...getQsProps(tabKey),
              },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data && data.statusCode === '200') {
            let rowData = data.result.rowData.map((row, index) => {
              return {
                ...row,
                isEditing: false,
                errors: {},
                hasError: false,
              };
            });
            params.success({
              rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: PARTS_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (!rowData.length && params.request.startRow < rowsPerRequest) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((error) => {
          params.fail();
        });
    },
  };
}

export function getHeaderDetails(details, tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'pc',
        formatParams(
          {
            //VIEW_PARTS_HEADER
            type: 'VIEW_PARTS_HEADER',
            view: `PARTS`,
            details: {
              email: Cookie.get('email'),
              partname: details.partname,
              supplierkey: details.supplierkey,
              customercode: details.customercode,
              customerplant: details.customerplant,
            },
          },
          getState
        )
      )
      .then((response) => {
        if (response) {
          const data = response.data;
          if (data) {
            dispatch(update(data, tabKey));
          }
          return data;
        }
        return response;
      });
  };
  function update(data, tabKey) {
    return { type: VIEW_PARTS_DETAIL_SUCCESS, data, tabKey };
  }
}

export function getMrp(details, tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: VIEW_MRP, tabKey });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'VIEW_MRP',
            view: `PARTS`,
            details: {
              email: Cookie.get('email'),
              partname: details.partname,
              supplierkey: details.supplierkey,
              customercode: details.customercode,
              customerplant: details.customerplant,
            },
          },
          getState
        )
      )
      .then((response) => {
        if (response) {
          const data = response.data;
          if (data) {
            dispatch(update(details.partname, data, tabKey));
          }
          return data;
        }
        return response;
      });
  };
  function update(part, data, tabKey) {
    return { type: VIEW_MRP_SUCCESS, part, data, tabKey };
  }
}
export function updateComments(headerVal) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: COMMENTS_UPDATE });
    return api
      .post('pcsubmitaction', {
        TXN: {
          Header: {
            txn_type: 'COMMENT_UPDATE',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
          },
          PartsArray: [headerVal],
        },
      })
      .then((response) => {
        const { data } = response;
        if (data) {
          const { TXN } = data;
          const overallStatus = TXN[0].return_msg;
          if (overallStatus === 'SUCCESS') {
            dispatch(update());
          } else {
            dispatch({
              type: COMMENTS_UPDATE_FAIL,
              payload: 'Error in updating user',
            });
          }
          return TXN[0].return_msg;
        }
      });
  };
  function update() {
    return { type: COMMENTS_UPDATE_SUCCESS };
  }
}

const handleCommitResponse = (TXN, t) => {
  const overallStatus = TXN[0].return_msg;
  if (overallStatus === 'SUCCESS') {
    message.success(t('data_updated_successfully'));
  } else if (overallStatus === 'FAILURE') {
    const failureResponse = TXN[0].CommitPartArray;
    Modal.error({
      title: t('failed_transactions'),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div
          style={{
            maxHeight: 200,
            minHeight: 100,
            overflowY: 'auto',
          }}>
          <div style={{ borderBottom: '1px solid #CCC', padding: 5 }}>
            Org Item(s)
          </div>
          {failureResponse.map((item) => (
            <div
              key={getUUID()}
              style={{
                borderBottom: '1px solid #CCC',
                padding: 5,
                fontSize: 12,
              }}>
              Plant: {item.customer_plant}
              Part: {item.part}
              Supplier Key: {item.supplier_key}
            </div>
          ))}
        </div>
      ),
    });
  } else {
    message.error(t('something_went_wrong'));
  }
};

function prepareAndSubmitData(nodes, _action, gridApi, lt, t) {
  let caItemDetails = [];
  if (nodes.length) {
    nodes.map(({ data }) => {
      caItemDetails.push({
        customer_plant: data.customer_plant,
        part: data.part,
        supplier_key: data.supplier_key,
      });
    });

    return (_dispatch, _getState, { api }) => {
      return api
        .post('pcsubmitaction', {
          TXN: {
            Header: {
              customer: 'SANM',
              requestor: Cookie.get('email'),
              source: 'PORTAL',
              txn_type: 'COPY_PROPOSALS',
              within_lt: lt,
              excel: 'NO',
              uuid: getUUID(),
            },
            PartsArray: caItemDetails,
          },
        })
        .then((response) => {
          const { data } = response;
          if (data) {
            const { TXN } = data;
            if (TXN) {
              handleCommitResponse(TXN, t);
              //Refresh grid
              gridApi.stopEditing();
              nodes.map((node) => {
                node.setSelected(false);
                node.updateData({
                  ...node.data,
                  isEditing: false,
                  errors: {},
                  hasError: false,
                });
              });
              gridApi.refreshServerSide({ purge: true });
              gridApi.refreshCells({ force: true });
            } else {
              message.error(t('something_went_wrong'));
            }
          } else {
            message.error(t('something_went_wrong'));
          }
        });
    };
  }
}

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
  tabKey,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookie.get('email'),
              details: {
                email: Cookie.get('email'),
                ...getQsProps(tabKey),
              },
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: Cookie.get('email'),
                ...getQsProps(tabKey),
              },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
              }) && cb(true);
            }
          }
          return response;
        });
    }
  };
}

export { getpartsFormColumns, getpartsFormRows, prepareAndSubmitData };
