import { message } from 'antd';
import {
  extractException,
  getLastRowIndex,
} from '../../../../../helpers/utils';
import service from '../../../../../service/Service';
import store from '../../../../../redux/store';

const COUNTRY_DROPDOWN_DATA = 'ums/cognito/COUNTRY_DROPDOWN_DATA';
const COUNTRY_DROPDOWN_DATA_SUCCESS =
  'ums/cognito/COUNTRY_DROPDOWN_DATA_SUCCESS';
const COUNTRY_DROPDOWN_DATA_FAIL = 'ums/cognito/COUNTRY_DROPDOWN_DATA_FAIL';
const COGNITO_CREATE_USER_DROPDOWN_DATA =
  'ums/cognito/COGNITO_CREATE_USER_DROPDOWN_DATA';
const COGNITO_CREATE_USER_DROPDOWN_DATA_SUCCESS =
  'ums/cognito/COGNITO_CREATE_USER_DROPDOWN_DATA_SUCCESS';
const COGNITO_CREATE_USER_DROPDOWN_DATA_FAIL =
  'ums/cognito/COGNITO_CREATE_USER_DROPDOWN_DATA_FAIL';
const GET_COGNITO_USER_SUCCESS = 'ums/cognito/store/GET_COGNITO_USER_SUCCESS';
const GET_COUNTRY_LIST_SUCCESS = 'ums/cognito/store/GET_COUNTRY_LIST_SUCCESS';
const ADD_COGNITO_USER = 'ums/cognito/store/ADD_COGNITO_USER';
const ADD_COGNITO_USER_SUCCESS = 'ums/cognito/store/ADD_COGNITO_USER_SUCCESS';
const COGNITO_USER_UPDATE = 'ums/cognito/store/COGNITO_USER_UPDATE';
const COGNITO_USER_UPDATE_SUCCESS =
  'ums/cognito/store/COGNITO_USER_UPDATE_SUCCESS';
const COGNITO_USER_DETAIL = 'ums/cognito/store/COGNITO_USER_DETAIL';
const COGNITO_USER_DETAIL_SUCCESS =
  'ums/cognito/store/COGNITO_USER_DETAIL_SUCCESS';
const COGNITO_USER_STATUS_UPDATE =
  'ums/cognito/store/COGNITO_USER_STATUS_UPDATE';
const COGNITO_USER_STATUS_UPDATE_SUCCESS =
  'ums/cognito/store/COGNITO_USER_STATUS_UPDATE_SUCCESS';
const COGNITO_USER_LIST_COLUMNS = 'ums/cognito/store/COGNITO_USER_LIST_COLUMNS';
const COGNITO_USER_LIST_COLUMNS_SUCCESS =
  'ums/cognito/store/COGNITO_USER_LIST_COLUMNS_SUCCESS';
const COGNITO_USER_LIST_COLUMNS_FAIL =
  'ums/cognito/store/COGNITO_USER_LIST_COLUMNS_FAIL';
const COGNITO_USER_LIST_ROWS_INFO_SUCCESS =
  'ums/cognito/store/COGNITO_USER_LIST_ROWS_INFO_SUCCESS';

const initialState = {
  isLoading: false,
  dropDownDataLoading: false,
  dropDownDataLoaded: false,
  dropDownData: [],
  createUserDropdownDataLoaded: false,
  createUserDropdownDataLoading: false,
  createUserDropdownData: [],
  addUserMsg: '',
  userName: '',
  userDetails: [],
  updateUserMsg: '',
  updatingUserState: false,
  storeColumns: {},
  rowsInfo: {
    endRow: 0,
    lastRow: 0,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_COGNITO_USER_SUCCESS:
      return { ...state, userName: action.data };
    case GET_COUNTRY_LIST_SUCCESS:
      return { ...state, countryList: action.data };

    case COUNTRY_DROPDOWN_DATA:
      return { ...state, dropDownDataLoading: true };
    case COUNTRY_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        dropDownDataLoaded: true,
        dropDownDataLoading: false,
        dropDownData: action.payload,
        error: null,
      };
    case COUNTRY_DROPDOWN_DATA_FAIL:
      return { ...state, dropDownDataLoading: false, error: action.payload };

    case COGNITO_CREATE_USER_DROPDOWN_DATA:
      return { ...state, createUserDropdownDataLoading: true };
    case COGNITO_CREATE_USER_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        createUserDropdownDataLoaded: true,
        createUserDropdownDataLoading: false,
        createUserDropdownData: action.payload,
        error: null,
      };
    case COGNITO_CREATE_USER_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        createUserDropdownDataLoading: false,
        error: action.payload,
      };

    case ADD_COGNITO_USER:
      return { ...state, isLoading: true };
    case ADD_COGNITO_USER_SUCCESS:
      return { ...state, addUserMsg: action.data, isLoading: false };
    case COGNITO_USER_UPDATE:
      return { ...state, isLoading: true };
    case COGNITO_USER_UPDATE_SUCCESS:
      return { ...state, updateUserMsg: 'SUCCESS', isLoading: false };
    case COGNITO_USER_DETAIL:
      return { ...state, isLoading: true };
    case COGNITO_USER_DETAIL_SUCCESS:
      return { ...state, userDetails: action.data, isLoading: false };
    case COGNITO_USER_STATUS_UPDATE:
      return {
        ...state,
        updatingUserState: true,
      };
    case COGNITO_USER_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        updatingUserState: false,
        userDetails: {
          ...state.userDetails,
          user_enabled: action.payload,
        },
      };
    case COGNITO_USER_LIST_COLUMNS:
      return { ...state, isLoading: true };
    case COGNITO_USER_LIST_COLUMNS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        storeColumns: action.payload,
        error: null,
      };
    case COGNITO_USER_LIST_COLUMNS_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case COGNITO_USER_LIST_ROWS_INFO_SUCCESS:
      return { ...state, rowsInfo: action.payload };
    default:
      return state;
  }
}

export function getColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: COGNITO_USER_LIST_COLUMNS });
    return api
      .post(
        'ums',
        formatParams(
          { type: 'AG_GRID_COLUMNS_COGNITO', view: 'USER_DETAILS_COGNITO' },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          dispatch({ type: COGNITO_USER_LIST_COLUMNS_SUCCESS, payload: data });
        } else {
          dispatch({
            type: COGNITO_USER_LIST_COLUMNS_FAIL,
            payload: 'Something went wrong! Please try again.',
          });
        }
      });
  };
}

export function getRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'AG_GRID_ROWS_COGNITO',
              view: 'USER_DETAILS_COGNITO',
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (Object.keys(data).length) {
            params.success({
              rowData: data.rowData,
              rowCount: getLastRowIndex(params.request, data.rowData),
            });
            const endRow =
              params.request.endRow > data.lastRow
                ? data.lastRow
                : params.request.endRow;
            store.dispatch({
              type: COGNITO_USER_LIST_ROWS_INFO_SUCCESS,
              payload: { lastRow: data.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

export function fetchDropdownData() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: COGNITO_CREATE_USER_DROPDOWN_DATA });
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'COGNITO_CREATE_USER_DROPDOWN_DATA',
            },
            getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            dispatch({
              type: COGNITO_CREATE_USER_DROPDOWN_DATA_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: COGNITO_CREATE_USER_DROPDOWN_DATA_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return response;
        });
    }
  };
}

export function add(formattedValues) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: ADD_COGNITO_USER });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'CREATE_USER_REQUEST',
            details: formattedValues,
          },
          getState
        )
      )
      .then((response) => {
        const data = response.data.returnMsg;
        if (data) {
          dispatch(update(data));
        }
        return response;
      });
  };
  function update(data) {
    return { type: ADD_COGNITO_USER_SUCCESS, data };
  }
}

export function updateUserDetails(formattedValues) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: COGNITO_USER_UPDATE });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'UPDATE_USER_ADMIN_REQUEST',
            details: formattedValues,
          },
          getState
        )
      )
      .then((response) => {
        let data = response.data.result;
        if (data.errorMessage && data.errorMessage != '') {
          dispatch(update());
          message.error(data.errorMessage);
        } else {
          message.success('User Updated Successfully');
          dispatch(update());
        }
        return response;
      });
  };
  function update() {
    return { type: COGNITO_USER_UPDATE_SUCCESS };
  }
}

export function userNameFetch(email) {
  return (dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'LDAP_COGNITO_USERNAME_CHECK',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const data = response.data.username;
        if (data) {
          dispatch(update(data));
        }
        return response;
      });
  };
  function update(data) {
    return { type: GET_COGNITO_USER_SUCCESS, data };
  }
}

export function getUserDetails(email) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: COGNITO_USER_DETAIL });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'VIEW_SPECIFIC_COGNITO_USER',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const data = response.data.result.userdetails[0];
        if (data) {
          dispatch(update(data));
        }
        return response;
      });
  };
  function update(data) {
    return { type: COGNITO_USER_DETAIL_SUCCESS, data };
  }
}

export function setUserStatus(email, enableUser) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: COGNITO_USER_STATUS_UPDATE });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: enableUser ? 'ENABLE_USER_REQUEST' : 'DISABLE_USER_REQUEST',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.returnCode == 0) {
          const {
            result: { userStatus },
          } = data;
          if (userStatus !== null) {
            message.success(userStatus);
            dispatch({
              type: COGNITO_USER_STATUS_UPDATE_SUCCESS,
              payload: enableUser ? 't' : 'f',
            });
          }
        } else {
          message.error('Error in updating user status. Please try again!');
        }
        return response;
      });
  };
}

export function resetUserPassword(email) {
  return (_dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'RESET_COGNITO_USER_PASSWORD_REQUEST',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        const { resetUserPasswordStatus } = data.result;
        if (data.returnCode == '0') {
          if (resetUserPasswordStatus) {
            message.success(resetUserPasswordStatus);
          }
        } else {
          message.error(extractException(data.result.errorMessage));
        }
        return response;
      });
  };
}

export function resetUser(email) {
  return (_dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'RESET_COGNITO_USER_REQUEST',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.returnCode == '0') {
          message.success('User reset success');
        } else {
          message.error('Error in resetting user. Please try again');
        }
        return response;
      });
  };
}

export function resendTempPasswd(email) {
  return (_dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'RESEND_COGNITO_TEMP_PASSWORD_REQUEST',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.returnCode == '0') {
          message.success('Temp password has been resent successfully.');
        } else {
          message.error('Error in resending temp password. Please try again');
        }
        return response;
      });
  };
}
