import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import store from '../../../../../redux/store';
import service from '../../../../../service/Service';
import {
  getLastRowIndex,
  getUUID,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../../helpers/utils';
import selectColumn from '../../../../common/ag-grid-select-column';
import Cookie from 'js-cookie';
import { message, Modal } from 'antd';
import {
  exportDTo,
  getMassDownloadGridParams,
} from '../../../../../helpers/export';

export const RSM_PLANT_OPEN_COLUMNS =
  'acp/candidate-item/RSM_PLANT_OPEN_COLUMNS';
export const RSM_PLANT_OPEN_COLUMNS_SUCCESS =
  'acp/candidate-item/RSM_PLANT_OPEN_COLUMNS_SUCCESS';
export const RSM_PLANT_OPEN_COLUMNS_FAIL =
  'acp/candidate-item/RSM_PLANT_OPEN_COLUMNS_FAIL';

export const RSM_PLANT_CLOSED_COLUMNS =
  'acp/candidate-item/RSM_PLANT_CLOSED_COLUMNS';
export const RSM_PLANT_CLOSED_COLUMNS_SUCCESS =
  'acp/candidate-item/RSM_PLANT_CLOSED_COLUMNS_SUCCESS';
export const RSM_PLANT_CLOSED_COLUMNS_FAIL =
  'acp/candidate-item/RSM_PLANT_CLOSED_COLUMNS_FAIL';

export const RSM_PLANT_HISTORY_COLUMNS =
  'acp/candidate-item/RSM_PLANT_HISTORY_COLUMNS';
export const RSM_PLANT_HISTORY_COLUMNS_SUCCESS =
  'acp/candidate-item/RSM_PLANT_HISTORY_COLUMNS_SUCCESS';
export const RSM_PLANT_HISTORY_COLUMNS_FAIL =
  'acp/candidate-item/RSM_PLANT_HISTORY_COLUMNS_FAIL';

export const RSM_PLANT_OPEN_ROWS_INFO =
  'acp/candidate-item/RSM_PLANT_OPEN_ROWS_INFO';
export const RSM_PLANT_CLOSED_ROWS_INFO =
  'acp/candidate-item/RSM_PLANT_CLOSED_ROWS_INFO';
export const RSM_PLANT_HISTORY_ROWS_INFO =
  'acp/candidate-item/RSM_PLANT_HISTORY_ROWS_INFO';

export const RSM_PLANT_DATA_SUBMIT = 'acp/candidate-item/RSM_PLANT_DATA_SUBMIT';
export const RSM_PLANT_DATA_SUBMIT_SUCCESS =
  'acp/candidate-item/RSM_PLANT_DATA_SUBMIT_SUCCESS';
export const RSM_PLANT_DATA_SUBMIT_FAIL =
  'acp/candidate-item/RSM_PLANT_DATA_SUBMIT_FAIL';

const initialState = {
  loadingOpen: false,
  loadingClosed: false,
  loadingHistory: false,
  rowsInfoOpen: {
    endRow: 0,
    lastRow: 0,
  },
  rowsInfoClosed: {
    endRow: 0,
    lastRow: 0,
  },
  rowsInfoHistory: {
    endRow: 0,
    lastRow: 0,
  },
  rsmPlantOpenColumns: {
    rowModelType: null,
  },
  rsmPlantClosedColumns: {
    rowModelType: null,
  },
  rsmPlantHistoryColumns: {
    rowModelType: null,
  },
  submittingItem: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RSM_PLANT_OPEN_COLUMNS:
      return { ...state, loadingOpen: true };
    case RSM_PLANT_CLOSED_COLUMNS:
      return { ...state, loadingClosed: true };
    case RSM_PLANT_HISTORY_COLUMNS:
      return { ...state, loadingHistory: true };
    case RSM_PLANT_OPEN_COLUMNS_SUCCESS:
      return {
        ...state,
        loadingOpen: false,
        loadingClosed: false,
        loadingHistory: false,
        loadedOpen: true,
        rsmPlantOpenColumns: action.payload,
        errorOpen: null,
      };
    case RSM_PLANT_CLOSED_COLUMNS_SUCCESS:
      return {
        ...state,
        loadingOpen: false,
        loadingClosed: false,
        loadingHistory: false,
        loadedClosed: true,
        rsmPlantClosedColumns: action.payload,
        errorClosed: null,
      };
    case RSM_PLANT_HISTORY_COLUMNS_SUCCESS:
      return {
        ...state,
        loadingOpen: false,
        loadingClosed: false,
        loadingHistory: false,
        loadedHistory: true,
        rsmPlantHistoryColumns: action.payload,
        errorHistory: null,
      };
    case RSM_PLANT_OPEN_COLUMNS_FAIL:
      return { ...state, loadingOpen: false, errorOpen: action.payload };
    case RSM_PLANT_CLOSED_COLUMNS_FAIL:
      return { ...state, loadingClosed: false, errorClosed: action.payload };
    case RSM_PLANT_HISTORY_COLUMNS_FAIL:
      return { ...state, loadingHistory: false, errorHistory: action.payload };
    case RSM_PLANT_OPEN_ROWS_INFO:
      return { ...state, rowsInfoOpen: action.payload, loadingOpen: false };
    case RSM_PLANT_CLOSED_ROWS_INFO:
      return { ...state, rowsInfoClosed: action.payload, loadingClosed: false };
    case RSM_PLANT_HISTORY_ROWS_INFO:
      return {
        ...state,
        rowsInfoHistory: action.payload,
        loadingClosed: false,
      };

    case RSM_PLANT_DATA_SUBMIT:
      return { ...state, submittingItem: true };
    case RSM_PLANT_DATA_SUBMIT_SUCCESS:
      return { ...state, submittingItem: false, submittedItem: true };
    case RSM_PLANT_DATA_SUBMIT_FAIL:
      return { ...state, submittingItem: false };
    default:
      return state;
  }
}

function getRsmPlantOpenColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: RSM_PLANT_OPEN_COLUMNS });
    const currentTime = getEpochTime();
    return api
      .post(
        'acp',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'RSM_PLANT_OPEN',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/ACP/getRsmPlantOpenColumns`, currentTime);
        const { data } = response;
        if (data && data.statusCode === '200') {
          data.result.columnDefs.unshift(selectColumn);
          dispatch({
            type: RSM_PLANT_OPEN_COLUMNS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: RSM_PLANT_OPEN_COLUMNS_FAIL,
            payload: 'Error in loading Closed View!',
          });
        }
      });
  };
}
function getRsmPlantOpenRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'RSM_PLANT_OPEN',
              gridParams: { ...params.request },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/ACP/getRsmPlantOpenRows`, currentTime);
          const { data } = response;
          if (data && data.statusCode === '200') {
            let rowData = data.result.rowData.map((row) => {
              return {
                ...row,
                isEditing: false,
                errors: {},
                hasError: false,
              };
            });
            params.success({
              rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: RSM_PLANT_OPEN_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (!rowData.length && params.request.startRow < rowsPerRequest) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

function getRsmPlantClosedColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: RSM_PLANT_CLOSED_COLUMNS });
    const currentTime = getEpochTime();
    return api
      .post(
        'acp',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'RSM_PLANT_CLOSED',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/ACP/getRsmPlantClosedColumns`, currentTime);
        const { data } = response;
        if (data && data.statusCode === '200') {
          dispatch({
            type: RSM_PLANT_CLOSED_COLUMNS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: RSM_PLANT_CLOSED_COLUMNS_FAIL,
            payload: 'Error in loading Closed View!',
          });
        }
      });
  };
}

function getRsmPlantClosedRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'RSM_PLANT_CLOSED',
              gridParams: { ...params.request },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/ACP/getRsmPlantClosedRows`, currentTime);
          const { data } = response;
          if (data && data.statusCode === '200') {
            params.success({
              rowData: data.result.rowData,
              rowCount: getLastRowIndex(params.request, data.result.rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: RSM_PLANT_CLOSED_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
            });
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.result.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

function getRsmPlantHistoryColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: RSM_PLANT_HISTORY_COLUMNS });
    const currentTime = getEpochTime();
    return api
      .post(
        'acp',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'RSM_PLANT_HISTORY',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/ACP/getRsmPlantHistoryColumns`, currentTime);
        const { data } = response;
        if (data && data.statusCode === '200') {
          dispatch({
            type: RSM_PLANT_HISTORY_COLUMNS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: RSM_PLANT_HISTORY_COLUMNS_FAIL,
            payload: 'Error in loading Closed View!',
          });
        }
      });
  };
}

function getRsmPlantHistoryRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'RSM_PLANT_HISTORY',
              gridParams: { ...params.request },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/ACP/getRsmPlantHistoryRows`, currentTime);
          const { data } = response;
          if (data && data.statusCode === '200') {
            params.success({
              rowData: data.result.rowData,
              rowCount: getLastRowIndex(params.request, data.result.rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: RSM_PLANT_HISTORY_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
            });
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.result.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

const handleFailedTxn = (failureResponse) => {
  Modal.error({
    title: 'Failed transactions!',
    icon: <ExclamationCircleOutlined />,
    content: (
      <div
        style={{
          maxHeight: 200,
          minHeight: 100,
          overflowY: 'auto',
        }}>
        <div style={{ borderBottom: '1px solid #CCC', padding: 5 }}>
          Org Item(s)
        </div>
        {failureResponse.map((item) => (
          <div
            key={item.acp_supplier_org_item}
            style={{
              borderBottom: '1px solid #CCC',
              padding: 5,
              fontSize: 12,
            }}>
            {item.acp_supplier_org_item}
          </div>
        ))}
      </div>
    ),
  });
};

function prepareAndSubmitData(nodes, action, gridApi) {
  let caItemDetails = [];
  if (nodes.length) {
    nodes.map(({ data }) => {
      caItemDetails.push({
        acp_annual_demand: data.acp_annual_demand,
        acp_implementation_status: data.acp_implementation_status,
        acp_plant_will_implement: data.acp_plant_will_implement,
        acp_expected_implementation_date: data.acp_expected_implementation_date,
        acp_plant_comments: data.acp_plant_comments,
        acp_standardized_plant_comments: data.acp_standardized_plant_comments,
        acp_feedback_to_supplier: data.acp_feedback_to_supplier,
        acp_item_status: data.acp_item_status,
        acp_master_seq_no: data.acp_master_seq_no,
        acp_rsm_owner: data.acp_rsm_owner,
        acp_supplier_org_item: data.acp_supplier_org_item,
      });
    });

    return (dispatch, _getState, { api }) => {
      dispatch({ type: RSM_PLANT_DATA_SUBMIT });
      const currentTime = getEpochTime();
      return api
        .post('acpsubmitaction', {
          TXN: {
            header: {
              transaction_type: 'RSM_COMMIT',
              status_type: action.toUpperCase(),
              uuid: getUUID(),
              customer: 'SANM',
              requestor: Cookie.get('email'),
              source: 'PORTAL',
              excel: 'NO',
            },
            details: caItemDetails,
          },
        })
        .then((response) => {
          gaPageviewTimingTitle(`/ACP/getRsmPlantData${action}`, currentTime);
          const { data } = response;
          if (data?.TXN) {
            const overallStatus = data.TXN[0].overall_txn_status;
            if (overallStatus === 'SUCCESS') {
              message.success('Data updated successfully!');
            } else if (overallStatus === 'FAILURE') {
              handleFailedTxn(data.TXN[0].FAILURE);
            } else {
              message.error('Something went wrong. Please try again!');
            }

            //Refresh grid
            gridApi.stopEditing();
            nodes.map((node) => {
              node.setSelected(false);
              node.updateData({
                ...node.data,
                isEditing: false,
                errors: {},
                hasError: false,
              });
            });
            gridApi.refreshServerSide({ purge: true });
            gridApi.refreshCells({ force: true });
          } else {
            message.error('Something went wrong. Please try again!');
          }
        });
    };
  }
}

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookie.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/ACP/getRsmPlantMassDownloadReport`,
            currentTime
          );
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
              }) && cb(true);
            }
          }

          return response;
        });
    }
  };
}

export {
  getRsmPlantOpenColumns,
  getRsmPlantOpenRows,
  getRsmPlantClosedColumns,
  getRsmPlantClosedRows,
  getRsmPlantHistoryColumns,
  getRsmPlantHistoryRows,
  prepareAndSubmitData,
};
