import React from 'react';
import Cookies from 'js-cookie';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { logout } from '../../../login/redux/modules/login';
import { gaPageviewTimingTitle, getEpochTime } from '../../../../helpers/utils';

const INIT_AUTHORIZATION = 'authorize/INIT_AUTHORIZATION';
const INIT_AUTHORIZATION_SUCCESS = 'authorize/INIT_AUTHORIZATION_SUCCESS';
const INIT_AUTHORIZATION_FAIL = 'authorize/INIT_AUTHORIZATION_FAIL';
const INIT_AUTHORIZATION_RESET = 'authorize/INIT_AUTHORIZATION_RESET';
const CHANGE_USER = 'authorize/CHANGE_USER';
const CHANGE_USER_SUCCESS = 'authorize/CHANGE_USER_SUCCESS';
const CHANGE_USER_FAIL = 'authorize/CHANGE_USER_FAIL';
const ADMIN_USER_REPLICATION_LIST = 'pc/ADMIN_USER_REPLICATION_LIST';
const ADMIN_USER_REPLICATION_LIST_SUCCESS =
  'pc/ADMIN_USER_REPLICATION_LIST_SUCCESS';
const ADMIN_USER_REPLICATION_LIST_FAIL = 'pc/ADMIN_USER_REPLICATION_LIST_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  initAuthorization: {},
  forceLogin: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_AUTHORIZATION:
    case CHANGE_USER:
      return { ...state, loading: true };
    case INIT_AUTHORIZATION_SUCCESS:
    case CHANGE_USER_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        initAuthorization: action.initAuthorization,
        forceLogin: action.forceLogin,
        error: null,
        isUserHasAccess: action.isUserHasAccess,
      };
    case INIT_AUTHORIZATION_FAIL:
    case CHANGE_USER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case INIT_AUTHORIZATION_RESET:
      return {
        ...state,
        ...initialState,
      };
    case ADMIN_USER_REPLICATION_LIST:
      return { ...state, loadingReplicationList: true };
    case ADMIN_USER_REPLICATION_LIST_SUCCESS:
      return {
        ...state,
        loadingReplicationList: false,
        pcUserList: action.payload,
      };
    case ADMIN_USER_REPLICATION_LIST_FAIL:
      return { ...state, loadingReplicationList: false };
    default:
      return state;
  }
}

const handleAuthorizeSuccess = (data, forceLogin) => {
  if (data && (data.returnMsg == 'SUCCESS' || data.statusCode == 402)) {
    Cookies.set('firstName', data.result.firstName, { expires: 365 });
    Cookies.set('lastName', data.result.lastName, { expires: 365 });
    if (data.result.defaultAccess == 'CPT') {
      Cookies.set('cpt_userrole', data.result.userRole);
    }

    return {
      type: INIT_AUTHORIZATION_SUCCESS,
      initAuthorization: data.result,
      forceLogin,
      isUserHasAccess: data.statusCode == 200,
    };
  }
};

const handleChangeUserSuccess = (data, forceLogin) => {
  if (data && (data.returnMsg == 'SUCCESS' || data.statusCode == 402)) {
    Cookies.set('firstName', data.result.firstName, { expires: 365 });
    Cookies.set('lastName', data.result.lastName, { expires: 365 });
    if (data.result.defaultAccess == 'CPT') {
      Cookies.set('cpt_userrole', data.result.userRole);
    }

    return {
      type: CHANGE_USER_SUCCESS,
      initAuthorization: data.result,
      forceLogin,
      isUserHasAccess: data.statusCode == 200,
    };
  }
};

const getForceLoginProp = (authorization, forceLogin) => {
  if (authorization.forceLogin !== '') {
    return { forceLogin: authorization.forceLogin };
  } else {
    return { forceLogin };
  }
};

export function getInitAuthorization(userAccess = '', forceLogin = '') {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: INIT_AUTHORIZATION });
    const authorization = getState().authorization;
    const currentTime = getEpochTime();
    return api
      .post(
        'authorize',
        formatParams(
          {
            type: 'AUTHORIZATION_DETAILS',
            details: {
              email: Cookies.get('email'),
              userAccess,
              ...getForceLoginProp(authorization, forceLogin),
            },
          },
          getState
        )
      )
      .then((response) => {
        if (userAccess)
          gaPageviewTimingTitle(`/${userAccess}/updateportal`, currentTime);

        const data = response.data;

        if (forceLogin === 'false') {
          const { navigate } = getState().layout;
          dispatch(logout(false));
          navigate('/');
        } else if (data.statusCode == 200 || data.statusCode == 402) {
          dispatch(handleAuthorizeSuccess(data, forceLogin));
        } else if (data.statusCode == 201) {
          Modal.confirm({
            title: data.result.title,
            icon: <ExclamationCircleOutlined />,
            content: data.result.message,
            mask: true,
            onOk: () => dispatch(getInitAuthorization('', 'true')),
            onCancel: () => dispatch(getInitAuthorization('', 'false')),
          });
        }

        return response;
      })
      .catch((err) => {
        dispatch({ type: INIT_AUTHORIZATION_FAIL });
        return err;
      });
  };
}

export function changeUser(email, forceLogin = '', userAccess = '') {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: CHANGE_USER });
    const authorization = getState().authorization;
    const currentTime = getEpochTime();
    return api
      .post(
        'authorize',
        formatParams(
          {
            type: 'CHANGE_USER',
            details: {
              email: Cookies.get('email'),
              simulationEmail: email,
              userAccess: '',
              ...getForceLoginProp(authorization, forceLogin),
            },
          },
          getState
        )
      )
      .then((response) => {
        if (userAccess)
          gaPageviewTimingTitle(`/${userAccess}/updateportal`, currentTime);

        const data = response.data;
        if (forceLogin === 'false') {
          const { navigate } = getState().layout;
          dispatch(logout(false));
          navigate('/');
        } else if (data.statusCode == 200 || data.statusCode == 402) {
          dispatch(handleChangeUserSuccess(data, ''));
        } else if (data.statusCode == 201) {
          Modal.confirm({
            title: data.result.title,
            icon: <ExclamationCircleOutlined />,
            content: data.result.message,
            mask: true,
            onOk: () => dispatch(getInitAuthorization('', 'true')),
            onCancel: () => dispatch(getInitAuthorization('', 'false')),
          });
        }

        return response;
      })
      .catch((err) => {
        dispatch({ type: CHANGE_USER_FAIL });
        return err;
      });
  };
}

export const resetInitAuthorization = () => {
  return (dispatch) => {
    dispatch({ type: INIT_AUTHORIZATION_RESET });
  };
};

export const getPcUserList = () => {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: ADMIN_USER_REPLICATION_LIST });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'ADMIN_USER_REPLICATION_LIST',
            details: {
              email: Cookies.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const data = response.data;

        if (data.statusCode == 200) {
          dispatch({
            type: ADMIN_USER_REPLICATION_LIST_SUCCESS,
            payload: data.result,
          });
        }

        return response;
      })
      .catch((err) => {
        dispatch({ type: ADMIN_USER_REPLICATION_LIST_FAIL });
        return err;
      });
  };
};
