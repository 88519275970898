import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const contentStyle = {
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
  minWidth: 200,
};

const content = <div style={contentStyle} />;

const Loading = (props) => (
  <div className='loading'>
    <Spin size='large' tip={props.tip ? props.tip : 'Loading..'}>
      {content}
    </Spin>
  </div>
);

Loading.propTypes = {
  tip: PropTypes.string,
};

export default Loading;
