import React from 'react';
import { Empty } from 'antd';
import PropTypes from 'prop-types';

const OverlayNoRows = ({ description = 'No Results Found!' }) => {
  return (
    <div className='overlay-no-rows'>
      <Empty description={description} />
    </div>
  );
};

OverlayNoRows.propTypes = {
  description: PropTypes.string.isRequired,
};

export default OverlayNoRows;
