import Cookies from 'js-cookie';
import { message } from 'antd';
import { isDevInstance, extractException } from '../../../../helpers/utils';

export const USER_TYPE_CHECK = 'login/USER_TYPE_CHECK';
export const USER_TYPE_CHECK_SUCCESS = 'login/USER_TYPE_CHECK_SUCCESS';
export const USER_TYPE_CHECK_FAIL = 'login/USER_TYPE_CHECK_FAIL';
export const LOGIN = 'login/LOGIN';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'login/LOGIN_FAIL';
export const MFA_CONFIGURED = 'login/MFA_CONFIGURED';
export const FORGOT_PASSWORD = 'login/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'login/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'login/FORGOT_PASSWORD_FAIL';
export const PASSWORD_CHANGE = 'login/PASSWORD_CHANGE';
export const PASSWORD_CHANGE_SUCCESS = 'login/PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAIL = 'login/PASSWORD_CHANGE_FAIL';
export const LOGOUT = 'login/LOGOUT';
export const LOGOUT_SUCCESS = 'login/LOGOUT_SUCCESS';

const initialState = {
  loaded: false,
  loading: false,
  mfaConfiguration: null,
  forgotPasswdDetails: null,
  cognito: false,
  error: null,
  logOut: false,
  user: {},
  forgotPasswd: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case USER_TYPE_CHECK:
    case LOGIN:
    case FORGOT_PASSWORD:
    case PASSWORD_CHANGE:
    case LOGOUT:
      return { ...state, loading: true };
    case USER_TYPE_CHECK_SUCCESS:
      return {
        ...state,
        cognito: JSON.parse(action.payload),
        loaded: true,
        loading: false,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        logOut: false,
        user: action.user,
        error: null,
      };
    case MFA_CONFIGURED:
      return {
        ...state,
        loading: false,
        mfaConfiguration: action.payload,
        error: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswd: true,
        forgotPasswdDetails: action.payload,
        loading: false,
        error: null,
      };
    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        forgotPasswd: false,
        forgotPasswdDetails: null,
        error: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        cognito: false,
        loading: false,
        loaded: false,
        error: null,
        mfaConfiguration: null,
        forgotPasswd: false,
        forgotPasswdDetails: null,
        user: null,
        logOut: true,
      };
    case USER_TYPE_CHECK_FAIL:
    case LOGIN_FAIL:
    case FORGOT_PASSWORD_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export function login(username, passwd, navigate, mfaConfig = null) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch(request());

    let type = '',
      details = '';

    if (mfaConfig) {
      type = 'MFA_AUTHENTICATION';
      details = {
        email: mfaConfig.email,
        challengeName: mfaConfig.challengeName,
        mfaCode: mfaConfig.mfaCode,
        session: mfaConfig.session,
        userName: mfaConfig.userName,
      };
    } else {
      type = 'AUTHENTICATION';
      details = {
        user_name: username,
        password: passwd,
      };
    }

    const handleLoginSuccess = (data) => {
      const { challengeName, session, userName } = data.result;
      if (challengeName && challengeName != 'NO_MFA') {
        dispatch({
          type: MFA_CONFIGURED,
          payload: {
            challengeName,
            session,
            userName: userName[0],
          },
        });
      } else {
        Cookies.set(
          'accessToken',
          data.result.authenticationResult.accessToken,
          {
            secure: !isDevInstance(),
            expires: 365,
          }
        );
        Cookies.set('continueSession', true, { expires: 365 });
        Cookies.set('sessionExpired', false, { expires: 365 });
        dispatch(success(data.result.authenticationResult));
        navigate('/dashboard');
      }
    };

    const handleLoginFailure = (data) => {
      if (
        extractException(data.result.errorMessage).indexOf(
          'Password reset required'
        ) !== -1
      ) {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          payload: null,
        });
      } else {
        dispatch(failure(extractException(data.result.errorMessage)));
      }
    };

    return api
      .post('authenticate', formatParams({ details, type }, getState))
      .then((response) => {
        const data = response.data;
        if (data && data.returnMsg == 'SUCCESS' && data.result) {
          handleLoginSuccess(data);
        } else if (data.returnMsg == 'FAILURE') {
          handleLoginFailure(data);
        }

        return response;
      })
      .catch((error) => {
        dispatch(failure(error));
        return error;
      });
  };

  function request() {
    return { type: LOGIN };
  }
  function success(user) {
    return { type: LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: LOGIN_FAIL, payload: error };
  }
}

export function logout(callApi = true) {
  const email = Cookies.get('email');
  Cookies.remove('accessToken');
  Cookies.remove('email');
  Cookies.remove('loginStatus');
  Cookies.remove('samlException');
  Cookies.remove('SESSION');
  Cookies.remove('JSESSIONID');
  Cookies.remove('isOpenReportHistory');
  Cookies.remove('isOpenReportHistoryTour');
  Cookies.remove('isOpenDashboardTour');
  Cookies.remove('offlineApi');
  Cookies.remove('continueSession');
  Cookies.remove('firstName');
  Cookies.remove('lastName');
  Cookies.remove('cpt_userrole');
  Cookies.remove('sessionExpired');

  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: LOGOUT_SUCCESS });
    if (callApi) {
      api
        .post(
          'authenticate',
          formatParams(
            {
              type: 'LOGOUT',
              details: { email },
            },
            getState
          )
        )
        .then((response) => {
          const {
            data: {
              result: { userdetails },
            },
          } = response;
          if (userdetails.length) {
            if (userdetails[0].Cognito === 'true') {
              window.location.reload();
            } else if (userdetails[0].RedirectURL) {
              window.open(userdetails[0].RedirectURL, '_self');
            }
          }
        });
    } else {
      window.location.reload();
    }
  };
}

export function checkUserType(email) {
  const handleSSO = (data, _dispatch) => {
    const key = 'updatable';
    message.loading({
      content: 'You are being redirected. Please wait..',
      key,
    });
    setTimeout(() => {
      window.open(data.result.userdetails[0]['RedirectURL'], '_self');
    }, 1000);
  };

  const handleCognito = (data, dispatch) => {
    if (data.result.userdetails[0]['Cognito'] == 'true') {
      if (
        data.result.userdetails[0]['awsStatus'] &&
        data.result.userdetails[0]['awsStatus'] == 'RESET_USER_PASSWORD'
      ) {
        dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: null });
      } else {
        dispatch(success(data.result.userdetails[0]['Cognito']));
      }
    }
  };

  const handleUser = (data, dispatch) => {
    // Set userType in cookie to handle external AD accounts
    Cookies.set('userType', data.result.userdetails[0]['userType'], {
      expires: 365,
    });

    if (data.result.userdetails[0]['Cognito'] === 'false') {
      handleSSO(data, dispatch);
    } else {
      handleCognito(data, dispatch);
    }
  };

  return (dispatch, getState, { api, formatParams }) => {
    dispatch(request());
    return api
      .post(
        'authenticate',
        formatParams(
          {
            type: 'USER_TYPE_CHECK',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const data = response.data;

        if (data && data.returnMsg == 'SUCCESS') {
          if (
            data.responseCode == 0 ||
            data.result == 'Not an registered user'
          ) {
            dispatch(error(data.result));
          } else {
            handleUser(data, dispatch);
            // Set walkthrough details of the user based on given email
            if (
              data.result.walkthrough &&
              Object.keys(data.result.walkthrough).length
            ) {
              Cookies.set(
                'walkthrough',
                JSON.stringify(data.result.walkthrough)
              );
            }
          }
        }
        return response;
      });
  };

  function request() {
    return { type: USER_TYPE_CHECK };
  }

  function success(data) {
    return { type: USER_TYPE_CHECK_SUCCESS, payload: data };
  }

  function error(data) {
    return { type: USER_TYPE_CHECK_FAIL, payload: data };
  }
}

export function submitNewPasswd(passwd, mfaConfig, navigate) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: LOGIN });
    return api
      .post(
        'authenticate',
        formatParams(
          {
            type: 'NEW_PASSWORD_REQUIRED',
            details: {
              email: Cookies.get('email'),
              newPassword: passwd,
              session: mfaConfig.session,
              userName: mfaConfig.userName,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          if (data.returnCode == 0) {
            if (data.result) {
              if (data.result.authenticationResult.accessToken) {
                Cookies.set(
                  'accessToken',
                  data.result.authenticationResult.accessToken,
                  {
                    secure: !isDevInstance(),
                    expires: 365,
                  }
                );
                Cookies.set('continueSession', true, { expires: 365 });
                Cookies.set('sessionExpired', false, { expires: 365 });
                dispatch({
                  type: LOGIN_SUCCESS,
                  user: data.result.authenticationResult,
                });
                navigate('/dashboard');
              }
            }
          } else if (data.returnCode == 1) {
            message.error(extractException(data.result.errorMessage));
            dispatch({
              type: LOGIN_FAIL,
              payload: extractException(data.result.errorMessage),
            });
          }
        }
      });
  };
}

export function forgotPasswd(email) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: FORGOT_PASSWORD });
    return api
      .post(
        'authenticate',
        formatParams(
          {
            type: 'FORGOT_PASSWORD_REQUEST',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          if (data.returnCode == 0) {
            const {
              result: { codeDeliveryDetails },
            } = data;
            if (codeDeliveryDetails !== null) {
              dispatch({
                type: FORGOT_PASSWORD_SUCCESS,
                payload: data.result.codeDeliveryDetails,
              });
            }
          } else if (data.returnCode == 1) {
            message.error(extractException(data.result.errorMessage));
            dispatch({
              type: FORGOT_PASSWORD_FAIL,
              payload: extractException(data.result.errorMessage),
            });
          }
        }
      });
  };
}

export function submitForgotPasswd(email, newPasswd, confirmCode) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PASSWORD_CHANGE });
    return api
      .post(
        'authenticate',
        formatParams(
          {
            type: 'CONFIRM_FORGOT_PASSWORD_REQUEST',
            details: {
              email: email,
              newPassword: newPasswd,
              confirmationCode: confirmCode,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.returnCode == 0) {
          const {
            result: { changePasswordStatus },
          } = data;
          if (changePasswordStatus !== null) {
            message.success(changePasswordStatus);
            dispatch({ type: PASSWORD_CHANGE_SUCCESS });
          }
        } else {
          message.error(extractException(data.result.errorMessage));
          dispatch({
            type: PASSWORD_CHANGE_FAIL,
            payload: extractException(data.result.errorMessage),
          });
        }
      });
  };
}
