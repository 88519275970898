import React, { useEffect, useState } from 'react';
import { Checkbox, Tooltip } from 'antd';
import PropTypes from 'prop-types';

const CustomDetailRowCheckboxRenderer = (props) => {
  const [checked, setChecked] = useState(false);

  const handleCheck = (event) => {
    setChecked(event.target.checked);
    props.node.setSelected(event.target.checked);
  };

  const refreshMasterRow = () => {
    props.context.masterGrid.api.refreshCells({
      force: true,
      columns: ['select'],
    });
  };

  useEffect(() => {
    if (props.node.isSelected()) {
      setChecked(true);
      refreshMasterRow();
    } else {
      setChecked(false);
      refreshMasterRow();
    }
  }, [props.node.isSelected()]);

  const getErrorDetails = () => {
    const errors = props.node.data.errors;
    if (errors && errors !== undefined) {
      let consolErrLength = Object.getOwnPropertyNames(
        props.node.data.errors
      ).length;
      let errorsConsolidated = [];
      if (consolErrLength > 0) {
        for (const property in props.node.data.errors) {
          if (props.node.data.errors[property].length) {
            errorsConsolidated.push(props.node.data.errors[property][0]);
          }
        }
      }

      if (errorsConsolidated.length) {
        return errorsConsolidated;
      }
    }

    return [];
  };

  const getErrorContent = () => {
    const errorsConsolidated = getErrorDetails();
    if (errorsConsolidated.length) {
      return (
        <>
          {errorsConsolidated.map((data) => (
            <div style={{ fontSize: 10 }} key={data}>
              {data}
            </div>
          ))}
        </>
      );
    }

    return null;
  };

  return (
    <Tooltip
      title={getErrorDetails().length ? getErrorContent() : ''}
      placement='rightBottom'>
      <span
        className={`row-checkbox-container ${
          getErrorDetails().length ? 'row-has-error' : null
        }`}>
        <Checkbox
          onClick={handleCheck}
          defaultChecked={false}
          checked={checked}
        />
      </span>
    </Tooltip>
  );
};

CustomDetailRowCheckboxRenderer.propTypes = {
  node: PropTypes.object,
  context: PropTypes.object,
};

export default CustomDetailRowCheckboxRenderer;
