import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tag, Card, Tooltip } from 'antd';
import { getUUID } from 'helpers/utils';
import {
  getCriteriaStyle,
  getNonStrCriteria,
  getObjectValue,
  getSelectedCriteria,
  getStrCriteria,
  showCriteria,
} from './component-utils';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SearchCriteria = (props) => {
  const [isCriteriaDetailVisible, setIsCriteriaDetailVisible] = useState(false);
  const [tabKey, setTabKey] = useState('dashboard');
  const searchCriteriaRef = useRef('');

  const { tabs } = useSelector(({ layout }) => ({
    tabs: layout.tabLayout.tabs,
  }));

  const defaultAccess = useSelector(
    ({ authorization }) => authorization.initAuthorization.defaultAccess
  );

  const search = useSelector(({ pc: { search } }) => search.quickSearch);

  useEffect(() => {
    if (props.tabKey) {
      setTabKey(props.tabKey);
    }
  }, [props.tabKey]);

  useEffect(() => {
    if (!tabs.length) {
      setTabKey('dashboard');
    }
  }, [tabs]);

  const tabData = getObjectValue(search, tabKey);

  const handleSearchCriteriaList = (criteria, key, cbCriList, cbCriArr) => {
    if (typeof criteria[key] !== 'string') {
      getNonStrCriteria(criteria, key, cbCriList, cbCriArr);
    } else {
      getStrCriteria(criteria, key, cbCriList, cbCriArr);
    }
  };

  const getSearchCriteria = (list = false) => {
    const criteria = getSelectedCriteria(tabData);
    const criteriaArr = [
      <strong style={{ color: '#ffeaa7' }} key={getUUID()}>
        Search Criteria:{' '}
      </strong>,
    ];

    const criteriaList = [];
    for (const key in criteria) {
      handleSearchCriteriaList(
        criteria,
        key,
        (criList) => criteriaList.push(criList),
        (criArr) => criteriaArr.push(criArr)
      );
    }

    if (list) {
      return [
        <Card
          key={getUUID()}
          size='small'
          style={{
            minHeight: 100,
            maxHeight: 200,
            wordBreak: 'break-word',
            overflowY: 'auto',
          }}>
          {criteriaList}
        </Card>,
      ];
    }

    return criteriaArr;
  };

  return showCriteria(tabData, defaultAccess) ? (
    <div className='search-criteria' style={getCriteriaStyle(props.view)}>
      <Tooltip
        title={getSearchCriteria(true)}
        trigger={'hover'}
        overlayInnerStyle={{ width: 350 }}
        overlayClassName={'search-criteria-tooltip'}
        color={'#FFFFFF'}
        placement='bottom'>
        <Link to onClick={(e) => e.preventDefault()}>
          <Tag
            color={'#b2bec3'}
            ref={searchCriteriaRef}
            onClick={() => setIsCriteriaDetailVisible(!isCriteriaDetailVisible)}
            style={{
              width: 'calc(100% - 23%)',
            }}>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                margin: 'auto',
              }}>
              {getSearchCriteria()}
            </div>
          </Tag>
        </Link>
      </Tooltip>
    </div>
  ) : null;
};

SearchCriteria.propTypes = {
  tabKey: PropTypes.string.isRequired,
  view: PropTypes.any,
};

export default SearchCriteria;
