import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  Row,
  Col,
  Menu,
  Dropdown,
  Avatar,
  Modal,
  theme,
  Select,
  Badge,
  Space,
} from 'antd';
import {
  AppstoreOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import {
  getInitAuthorization,
  getPcUserList,
} from './redux/modules/authorization';
import PulseLoader from 'react-spinners/PulseLoader';
import {
  UseGAEventsTracker,
  isAdmin as isSimulatorAdmin,
} from './../../helpers/utils';
import logo from '../../assets/img/logo/logo.svg';
import SiderProfile from './Sider/SiderProfile';
import { destroyTabs } from './redux/modules/layout';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

const Header = (props) => {
  const [simulatorMenuVisibility, setSimulatorMenuVisibility] = useState(false);
  const [user1, setUser1] = useState('');
  const [user2, setUser2] = useState('');
  const isAdmin = isSimulatorAdmin();

  const { portalAccess, authorizationRequest, portalTitle, tabs, userDetails } =
    useSelector(
      ({ authorization, layout: { tabLayout }, profile: { user } }) => ({
        tabs: tabLayout.tabs,
        portalAccess: authorization.initAuthorization.portalAccess,
        authorizationRequest: authorization.authorizationRequest,
        portalTitle: authorization.initAuthorization.portalTitle,
        userDetails: user,
      }),
      shallowEqual
    );

  const defaultAccess = useSelector(
    ({ authorization }) => authorization.initAuthorization.defaultAccess
  );

  const { pcUserList, loadingReplicationList } = useSelector(
    ({ authorization }) => ({
      pcUserList: authorization.pcUserList,
      loadingReplicationList: authorization.loadingReplicationList,
    })
  );

  const dispatch = useDispatch();
  const { toggle } = props;

  const {
    token: { colorPrimaryActive, colorPrimary, colorText },
  } = theme.useToken();

  const replicatedUserEmail = Cookies.get('simulationEmail');
  const originalUser = Cookies.get('email');
  const userChanged = Cookies.get('userChanged');

  useEffect(() => {
    if (isAdmin) {
      dispatch(getPcUserList());
    }
  }, [isAdmin]);

  useEffect(() => {
    if (userChanged == 'true') {
      setUser1(replicatedUserEmail);
      setUser2(originalUser);
    } else {
      setUser1(originalUser);
      setUser2(replicatedUserEmail);
    }
  }, [userChanged]);

  const changeModule = (module) => {
    if (tabs.length) {
      Modal.confirm({
        title: 'Changing the Profile Data will close all the opened tabs',
        icon: <ExclamationCircleOutlined />,
        okText: 'Continue',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          dispatch(destroyTabs());
          dispatch(getInitAuthorization(module, 'switch'));
        },
      });
    } else {
      dispatch(getInitAuthorization(module, 'switch'));
    }
  };

  const GAEventTracker = UseGAEventsTracker('External links');

  const getModuleStyle = (access) => {
    let style = { padding: 10 };
    if (defaultAccess === access) {
      style = { ...style, background: colorPrimaryActive };
    }

    return style;
  };

  const multiModuleMenus = () => {
    return [
      {
        key: 'multiModMenu',
        label: (
          <Row>
            <Col span={24}>
              <Row>
                {portalAccess?.length
                  ? portalAccess.map((data) => {
                      return (
                        <Col span={8} key={data.access}>
                          <div //NOSONAR
                            title={data.title}
                            style={getModuleStyle(data.access)}
                            onClick={() => changeModule(data.access)}>
                            <a //NOSONAR
                              target='_blank'
                              rel='noopener noreferrer'
                              onClick={() =>
                                GAEventTracker(data.access.toUpperCase())
                              }>
                              <Avatar
                                className='multi-module-avatar'
                                style={{ backgroundColor: colorPrimary }}
                                size='large'>
                                {data.access.substr(0, 1).toUpperCase()}
                              </Avatar>
                              <span
                                style={{
                                  display: 'block',
                                  marginTop: 5,
                                  color:
                                    defaultAccess === data.access
                                      ? colorPrimary
                                      : colorText,
                                }}>
                                {data.access}
                              </span>
                            </a>
                          </div>
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </Col>
          </Row>
        ),
      },
    ];
  };

  const getHeaderMenus = () => {
    const menuItems = [];
    if (portalAccess) {
      if (portalAccess.length > 1) {
        menuItems.push({
          key: 'multiModMenuIcon',
          label: (
            <Dropdown
              menu={{ items: multiModuleMenus() }}
              trigger={['click']}
              overlayClassName='multi-mod-menu'
              placement='bottomLeft'>
              <AppstoreOutlined style={{ color: '#FFFFFF', fontSize: 22 }} />
            </Dropdown>
          ),
        });
      }
    }

    return menuItems;
  };

  const handleUserSwap = (user2, user1) => {
    if (user2 === originalUser) {
      Cookies.set('userChanged', false);
      setUser1(user2);
      setUser2(user1);
    } else {
      Cookies.set('userChanged', true);
      setUser1(user1);
      setUser2(user2);
    }

    window.location.reload();
  };

  const changeUserAccess = (userToChange) => {
    const [email, name] = userToChange.split('$$$');
    Cookies.set('simulationEmail', email);
    Cookies.set('firstName', name);
    Cookies.set('userChanged', true);
    window.location.reload();
  };

  const getReplicationList = () => {
    if (pcUserList?.length) {
      return pcUserList.map((data) => ({
        value: `${data.email}$$$${data.name}`,
        label: data.email,
      }));
    }

    return [];
  };

  return (
    <Row>
      <Col flex={125}>
        <Avatar shape='square' className='logo-avator' src={logo} />
        {props.collapsed ? (
          <MenuUnfoldOutlined className='trigger-red' onClick={toggle} />
        ) : (
          <MenuFoldOutlined className='trigger' onClick={toggle} />
        )}
      </Col>
      <Col flex={400}>
        <div className='title-content' style={{ textAlign: 'center' }}>
          <div className='title-caption'>
            {authorizationRequest && (
              <PulseLoader size={6} color={'#FFFFFF'} loading={true} />
            )}
            {portalTitle || ''}
          </div>
        </div>
      </Col>
      <Col flex={'auto'} style={{ marginTop: 5, marginRight: 10 }}>
        <>
          <Menu
            items={getHeaderMenus()}
            mode='horizontal'
            className='header-menu-option'
          />
          {userDetails?.userDetailLoaded && (
            <>
              <SiderProfile {...userDetails} />{' '}
              {defaultAccess === 'PC' && isAdmin && (
                <Dropdown
                  trigger={['click']}
                  overlayClassName='simulator-menu'
                  overlayStyle={{
                    width: 350,
                  }}
                  open={simulatorMenuVisibility}
                  menu={{
                    items: [
                      {
                        label: (
                          <div className='switch-user'>
                            {user2 && user2 !== '' ? (
                              <>
                                <p>
                                  {user1}{' '}
                                  <Space direction='horizontal'>
                                    <Badge color='#55efc4' />
                                  </Space>
                                </p>
                                <SwapOutlined
                                  rotate={90}
                                  style={{
                                    color: '#00b894',
                                    fontSize: 18,
                                    fontWeight: 'bold',
                                  }}
                                  onClick={() => handleUserSwap(user2, user1)}
                                />
                                <p>{user2}</p>
                                <Space direction='vertical' />
                              </>
                            ) : null}
                            <Select
                              showSearch
                              loading={loadingReplicationList}
                              onChange={changeUserAccess}
                              style={{
                                width: 300,
                                marginTop: 10,
                                marginBottom: 10,
                                textAlign: 'left',
                              }}
                              placeholder='Enter name to search'
                              optionFilterProp='children'
                              filterOption={(input, option) =>
                                (option?.label ?? '').includes(input)
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '')
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? '').toLowerCase()
                                  )
                              }
                              options={getReplicationList()}
                            />
                          </div>
                        ),
                      },
                    ],
                  }}>
                  <DownOutlined
                    style={{ color: '#FFFFFF', fontSize: 12 }}
                    onClick={() =>
                      setSimulatorMenuVisibility((prevState) => !prevState)
                    }
                  />
                </Dropdown>
              )}
            </>
          )}
        </>
      </Col>
    </Row>
  );
};

Header.propTypes = {
  toggle: PropTypes.bool,
  collapsed: PropTypes.bool,
};

export default Header;
