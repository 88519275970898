import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  changeUser,
  getInitAuthorization,
} from '../modules/layout/redux/modules/authorization';
import Loading from '../modules/layout/Loading';
import Cookies from 'js-cookie';
import Container from '../modules/layout/Layout';
import PropTypes from 'prop-types';

function PrivateRoute({ component: Component, ...rest }) {
  const authenticating = useSelector(
    ({ authentication }) => authentication.loggingIn
  );

  const dispatch = useDispatch();
  const accessToken = Cookies.get('accessToken');
  const continueSession = Cookies.get('continueSession');
  const replicatedUserEmail = Cookies.get('simulationEmail');
  const userChanged = Cookies.get('userChanged');

  useEffect(() => {
    if (userChanged === 'true') {
      dispatch(changeUser(replicatedUserEmail));
    }
  }, [userChanged]);

  useEffect(() => {
    if (
      continueSession === 'true' &&
      accessToken &&
      (userChanged === 'false' || !userChanged)
    ) {
      dispatch(getInitAuthorization());
    }
  }, [continueSession]);

  if (authenticating) {
    return <Loading />;
  }

  if (accessToken && continueSession === 'true') {
    return (
      <Container>
        <Component {...rest} />
      </Container>
    );
  }

  return <Navigate to='/' replace={true} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
