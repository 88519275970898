import { combineReducers } from 'redux';
import store from '../store/redux/store';
import dashboard from '../dashboard/redux/dashboard';
import amsadd from '../amsadd/redux/store';
import amsadmin from '../amsadmin/redux/store';
import cpt from '../cpt/redux/store';
import ssp from '../ssp/redux/store';
import om from '../pc/om/redux/store';
import agility from '../pc/agility/redux/store';
import cognito from '../pc/cognito/redux/store';

const reducers = combineReducers({
  store,
  dashboard,
  amsadd,
  amsadmin,
  cpt,
  ssp,
  om,
  agility,
  cognito
});
export default reducers;
