import Cookies from 'js-cookie';
import service from 'service/Service';
import store from '../../../../redux/store';
import {
  getLastRowIndex,
  gaPageviewTimingTitle,
  getEpochTime,
  getUUID,
} from 'helpers/utils';
import { exportDTo, getMassDownloadGridParams } from 'helpers/export';

const REPORT_SETTINGS = 'cds/REPORT_SETTINGS';
const REPORT_SETTINGS_SUCCESS = 'cds/REPORT_SETTINGS_SUCCESS';
const REPORT_SETTINGS_FAIL = 'cds/REPORT_SETTINGS_FAIL';
const REPORT_SETTINGS_ROWS_INFO = 'cds/REPORT_SETTINGS_ROWS_INFO';

const initialState = {
  newTab0: {
    loaded: false,
    columns: {},
    rowsInfo: {
      endRow: 0,
      lastRow: 0,
    },
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REPORT_SETTINGS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loaded: false,
          loading: true,
          columns: {},
          error: null,
        },
      };
    case REPORT_SETTINGS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loaded: true,
          loading: false,
          columns: action.payload,
        },
      };
    case REPORT_SETTINGS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
        },
      };
    case REPORT_SETTINGS_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          rowsInfo: action.payload,
        },
      };
    default:
      return state;
  }
}

//below function is for calling api service and handling response for deleting an existing report
export function deleteReport(formattedValues, srNum) {
  return (_dispatch, _getState, { api }) => {
    let reportDetails = [];
    reportDetails.push({
      report_id: formattedValues.report_id,
      report_name: formattedValues.portal_report,
      cr_no: srNum,
    });

    return api
      .post('cdssubmitaction', {
        TXN: {
          Header: {
            txn_type: 'DELETE_REPORT',
            uuid: getUUID(),
            requestor: Cookies.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          ReportFormArray: reportDetails,
        },
      })
      .then((response) => {
        return response;
      });
  };
}

export function getColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: REPORT_SETTINGS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'cds',
        formatParams(
          { type: 'AG_GRID_COLUMNS', view: 'REPORT_SETTINGS_V' },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/CDS/GET_REPORT_SETTINGS_COLUMNS`, currentTime);
        const { data } = response;
        if (data?.result) {
          const delActionRenderer = {
            headerName: 'Action',
            headerTooltip: 'Action',
            field: 'action',
            tooltipField: 'action',
            colId: 'action',
            cellRenderer: 'agCustomDeleteActionRenderer',
            filter: false,
            width: 30,
            cellStyle: {
              textAlign: 'left',
            },
          };

          data.result.columnDefs.push(delActionRenderer);

          dispatch({
            type: REPORT_SETTINGS_SUCCESS,
            payload: data.result,
            tabKey,
          });
        } else {
          dispatch({
            type: REPORT_SETTINGS_FAIL,
            payload: 'Something went wrong! Please try again.',
            tabKey,
          });
        }
      });
  };
}

export function getRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'REPORT_SETTINGS_V',
              gridParams: { ...params.request },
              details: {
                email: Cookies.get('email'),
                screen: 'reports',
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/CDS/GET_REPORT_SETTINGS_ROWS`, currentTime);
          const { data } = response;

          if (Object.keys(data).length) {
            params.success({
              rowData: data.result.rowData,
              rowCount: getLastRowIndex(params.request, data.result.rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: REPORT_SETTINGS_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.result.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

export function getMassDownloadReport(
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi
) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: 'REPORT_SETTINGS_V',
              exportType,
              totalRows,
              email: Cookies.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
              details: {
                email: Cookies.get('email'),
                screen: 'reports',
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'REPORT_SETTINGS_V',
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: Cookies.get('email'),
                screen: 'reports',
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_REPORT_SETTINGS_MASS_DOWNLOAD_REPORT`,
            currentTime
          );
          const { data } = response;
          if (Object.keys(data).length) {
            exportDTo({
              columnApi,
              fileName: `CDS_REPORT_SETTINGS`,
              data: data.result.rowData,
              exportType,
            }) && cb(true);
          }

          return response;
        });
    }
  };
}
