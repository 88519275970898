import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Tour, Button } from 'antd';
import TourActionButton from '../TourActionButton';
import { destroyTabs } from '../../layout/redux/modules/layout';
import { setWalkthrough } from '../redux/tour';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';

const Helpwalkpc = (props) => {
  const [open, setOpen] = useState(false);
  const [getSteps, setGetSteps] = useState([]);
  const tourReferences = useSelector(({ tour }) => tour.tourReferences);
  const menuOptions = useSelector(
    ({ layout }) => layout.menuOptions,
    shallowEqual
  );
  useEffect(() => {
    const handleClickCard = (event) => {
      let getContentValue = event.target.innerHTML;
      let getContentLength = getContentValue.length;
      if (
        getContentValue != '' &&
        getContentValue != 'Next' &&
        getContentValue != 'Previous' &&
        getContentLength < 25
      ) {
        enableWalkthrough();
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickCard, true);
    getStepsSider();
  }, [tourReferences]);

  const dispatch = useDispatch();

  const openTour = () => {
    props.handleVisiblity();
    dispatch(destroyTabs());
    setOpen(true);
  };
  const enableWalkthrough = () => {
    Cookie.set('walkthroughAsnHistory', 'Yes');
    Cookie.set('walkthroughAsn', 'Yes');
    Cookie.set('walkthroughFc', 'Yes');
    Cookie.set('walkthroughInvHistory', 'Yes');
    Cookie.set('walkthroughInv', 'Yes');
    Cookie.set('walkthroughNbs', 'Yes');
    Cookie.set('walkthroughParts', 'Yes');
    Cookie.set('walkthroughRes', 'Yes');
    Cookie.set('walkthroughRtfFirm', 'Yes');
    Cookie.set('walkthroughRtfHistory', 'Yes');
    Cookie.set('walkthroughRtfRecon', 'Yes');
    Cookie.set('walkthroughSmiHistory', 'Yes');
    Cookie.set('walkthroughSmiPulls', 'Yes');
    Cookie.set('walkthroughVciHistory', 'Yes');
    Cookie.set('walkthroughVciShipment', 'Yes');
    Cookie.set('walkthroughVciVendor', 'Yes');
  };
  const closeTour = () => {
    dispatch(setWalkthrough('PC', 'dashboard', 'Skip'));
    setOpen(false);
  };

  const getStepsSider = () => {
    let steps = [
      {
        title: 'Partner Connect',
        description: (
          <div>
            Welcome to Partner Connect Portal! We put this short tour together
            to show you some key features. You can dismiss this message and come
            back to it at any time using the Support Link.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: null,
      },
    ];
    let menuSteps = {
      PARTS: {
        title: 'Partner Connect',
        description: (
          <div>
            Please click the Parts Menu item to proceed with the next tip.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.pcParts.current,
      },
      FC: {
        title: 'Partner Connect',
        description: (
          <div>
            Please click the FC Menu item to proceed with the next tip.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.pcFc.current,
      },
      RTF: {
        title: 'Partner Connect',
        description: (
          <div>
            Please click the RTF Menu item to proceed with the next tip.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.pcRtf.current,
      },
      SMI: {
        title: 'Partner Connect',
        description: (
          <div>
            Please click the SMI Menu item to proceed with the next tip.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.pcSmi.current,
      },
      ASN: {
        title: 'Partner Connect',
        description: (
          <div>
            Please click the ASN Menu item to proceed with the next tip.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.pcAsn.current,
      },
      VCI: {
        title: 'Partner Connect',
        description: (
          <div>
            Please click the VCI Menu item to proceed with the next tip.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.pcVci.current,
      },
      INV: {
        title: 'Partner Connect',
        description: (
          <div>
            Please click the INV Menu item to proceed with the next tip.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.pcInv.current,
      },
      NBS: {
        title: 'Partner Connect',
        description: (
          <div>
            Please click the NBS Menu item to proceed with the next tip.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.pcNbs.current,
      },
      RES: {
        title: 'Partner Connect',
        description: (
          <div>
            Please click the RES Menu item to proceed with the next tip.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.pcRes.current,
      },
    };
    menuOptions.map((data) => {
      if (menuSteps[data.name] != undefined) steps.push(menuSteps[data.name]);
    });
    steps.push(
      {
        title: 'Partner Connect',
        description: (
          <div>
            Press this Excel Download button to download the latest MS Excel
            template. All the functionality of the portal is available in the
            Excel Download template. You need only to download this template
            once (not every time you wish to see the PC data).
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.excelDownload.current,
      },
      {
        title: 'Partner Connect',
        description: (
          <div>
            If you download more than 2000 records using this download button,
            we will perform the download offline and send you a mail when the
            download is ready. The download data will also remain available in
            the Export History Form available by selecting the “Export History”
            menu item in the Left Side Menu.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.exportHistoryCdsTour.current,
      },
      {
        title: 'Partner Connect',
        description: (
          <div>
            The Support area has documentation for PC, video help and contact
            information to seek further system help.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.profileSupportDoc.current,
      },
      {
        title: 'Partner Connect',
        description: (
          <div>
            Quick Search is a feature that allows users to find information or
            results quickly on a portal.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.quickSearch.current,
      },
      {
        title: 'Partner Connect',
        description: (
          <div>
            Saved searches allow you to store the criteria for searching for
            filters so that you can perform the same search later without
            re-entering it.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.savedSearch.current,
      },
      {
        title: 'Partner Connect',
        description: (
          <div>
            To refresh the dashboard data, click here.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.refresh.current,
      },
      {
        title: 'Partner Connect',
        description: (
          <div>
            Customizing the dashboard view is possible here.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.changeView.current,
      },
      {
        title: 'Partner Connect',
        description: (
          <div>
            Here, displayed the consolidated data's are associated with the
            particular user.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.pcDashboard.current,
      },
      {
        title: 'Partner Connect',
        description: (
          <div>
            Use the profile section to change your download text delimiter
            Parameter and to change your password when necessary.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.profileSpaceTour.current,
      },
      {
        title: 'Partner Connect',
        description: (
          <div>
            Thanks for taking the tour! If you want to see this tour again,
            please look to the link in the support section.
            <br />
            <TourActionButton
              type='skip'
              screen='dashboard'
              label='Skip'
              closeTour={closeTour}
            />
          </div>
        ),
        target: () => tourReferences.profileSupportDoc.current,
      }
    );
    setGetSteps(steps);
  };

  return (
    <div>
      <Button type='primary' className='left-walkthrough' onClick={openTour}>
        Take a tour!
      </Button>
      <Tour open={open} onClose={closeTour} steps={getSteps} />
    </div>
  );
};

Helpwalkpc.propTypes = {
  handleVisiblity: PropTypes.func,
};

export default Helpwalkpc;
