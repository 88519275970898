import CustomHeaderCheckboxRenderer from './CustomHeaderCheckboxRenderer';
import CustomRowCheckboxRenderer from './CustomRowCheckboxRenderer';

const commonProps = {
  width: 40,
  filter: false,
  sortable: false,
  headerName: '',
  suppressMenu: true,
  suppressSizeToFit: true,
  suppressMovable: true,
  suppressNavigable: true,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  editable: false,
  suppressCellFlash: true,
  resizable: false,
  lockPosition: true,
};

export default {
  ...commonProps,
  field: 'select',
  colId: 'select',
  headerComponent: CustomHeaderCheckboxRenderer,
  cellRenderer: CustomRowCheckboxRenderer,
  pinned: 'left',
};
