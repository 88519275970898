import { message } from 'antd';
import {
  extractException,
  getLastRowIndex,
  getUUID,
} from '../../../../helpers/utils';
import service from '../../../../service/Service';
import store from '../../../../redux/store';
import Cookie from 'js-cookie';

const COUNTRY_DROPDOWN_DATA = 'ums/COUNTRY_DROPDOWN_DATA';
const COUNTRY_DROPDOWN_DATA_SUCCESS = 'ums/COUNTRY_DROPDOWN_DATA_SUCCESS';
const COUNTRY_DROPDOWN_DATA_FAIL = 'ums/COUNTRY_DROPDOWN_DATA_FAIL';
const CREATE_USER_DROPDOWN_DATA = 'ums/CREATE_USER_DROPDOWN_DATA';
const CREATE_USER_DROPDOWN_DATA_SUCCESS =
  'ums/CREATE_USER_DROPDOWN_DATA_SUCCESS';
const CREATE_USER_DROPDOWN_DATA_FAIL = 'ums/CREATE_USER_DROPDOWN_DATA_FAIL';
const GET_UMS_USER_SUCCESS = 'ums/store/GET_UMS_USER_SUCCESS';
const GET_COUNTRY_LIST_SUCCESS = 'ums/store/GET_COUNTRY_LIST_SUCCESS';
const ADD_UMS_USER = 'ums/store/ADD_UMS_USER';
const ADD_UMS_USER_SUCCESS = 'ums/store/ADD_UMS_USER_SUCCESS';
const UMS_USER_UPDATE = 'ums/store/UMS_USER_UPDATE';
const UMS_USER_UPDATE_SUCCESS = 'ums/store/UMS_USER_UPDATE_SUCCESS';
const UMS_USER_DETAIL = 'ums/store/UMS_USER_DETAIL';
const UMS_USER_DETAIL_SUCCESS = 'ums/store/UMS_USER_DETAIL_SUCCESS';
const USER_STATUS_UPDATE = 'ums/store/USER_STATUS_UPDATE';
const USER_STATUS_UPDATE_SUCCESS = 'ums/store/USER_STATUS_UPDATE_SUCCESS';
const UMS_USER_LIST_COLUMNS = 'ums/store/UMS_USER_LIST_COLUMNS';
const UMS_USER_LIST_COLUMNS_SUCCESS = 'ums/store/UMS_USER_LIST_COLUMNS_SUCCESS';
const UMS_USER_LIST_COLUMNS_FAIL = 'ums/store/UMS_USER_LIST_COLUMNS_FAIL';
const UMS_USER_LIST_ROWS_INFO_SUCCESS =
  'ums/store/UMS_USER_LIST_ROWS_INFO_SUCCESS';
//below 2 const is for OM edit existing user details
const ADD_DEPOT_DETAILS = 'ums/store/ADD_DEPOT_DETAILS';
const ADD_DEPOT_DETAILS_SUCCESS = 'ums/store/ADD_DEPOT_DETAILS_SUCCESS';

//below 2 const is for OM delete existing user details
const DELETE_DEPOT_DETAILS = 'ums/store/DELETE_DEPOT_DETAILS';
const DELETE_DEPOT_DETAILS_SUCCESS = 'ums/store/DELETE_DEPOT_DETAILS_SUCCESS';

const initialState = {
  isLoading: false,
  dropDownDataLoading: false,
  dropDownDataLoaded: false,
  dropDownData: [],
  createUserDropdownDataLoaded: false,
  createUserDropdownDataLoading: false,
  createUserDropdownData: [],
  addUserMsg: '',
  userName: '',
  userDetails: [],
  updateUserMsg: '',
  updatingUserState: false,
  storeColumns: {},
  addCpDetails: [],
  deleteCpDetails: [],
  rowsInfo: {
    endRow: 0,
    lastRow: 0,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_UMS_USER_SUCCESS:
      return { ...state, userName: action.data };
    case GET_COUNTRY_LIST_SUCCESS:
      return { ...state, countryList: action.data };

    case COUNTRY_DROPDOWN_DATA:
      return { ...state, dropDownDataLoading: true };
    case COUNTRY_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        dropDownDataLoaded: true,
        dropDownDataLoading: false,
        dropDownData: action.payload,
        error: null,
      };
    case COUNTRY_DROPDOWN_DATA_FAIL:
      return { ...state, dropDownDataLoading: false, error: action.payload };

    case CREATE_USER_DROPDOWN_DATA:
      return { ...state, createUserDropdownDataLoading: true };
    case CREATE_USER_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        createUserDropdownDataLoaded: true,
        createUserDropdownDataLoading: false,
        createUserDropdownData: action.payload,
        error: null,
      };
    case CREATE_USER_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        createUserDropdownDataLoading: false,
        error: action.payload,
      };

    case ADD_UMS_USER:
      return { ...state, isLoading: true };
    case ADD_UMS_USER_SUCCESS:
      return { ...state, addUserMsg: action.data, isLoading: false };
    case UMS_USER_UPDATE:
      return { ...state, isLoading: true };
    case UMS_USER_UPDATE_SUCCESS:
      return { ...state, updateUserMsg: 'SUCCESS', isLoading: false };
    case UMS_USER_DETAIL:
      return { ...state, isLoading: true };
    case UMS_USER_DETAIL_SUCCESS:
      return { ...state, userDetails: action.data, isLoading: false };
    case USER_STATUS_UPDATE:
      return {
        ...state,
        updatingUserState: true,
      };
    case USER_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        updatingUserState: false,
        userDetails: {
          ...state.userDetails,
          user_enabled: action.payload,
        },
      };
    case UMS_USER_LIST_COLUMNS:
      return { ...state, isLoading: true };
    case UMS_USER_LIST_COLUMNS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        storeColumns: action.payload,
        error: null,
      };
    case UMS_USER_LIST_COLUMNS_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case UMS_USER_LIST_ROWS_INFO_SUCCESS:
      return { ...state, rowsInfo: action.payload };
    case ADD_DEPOT_DETAILS:
      return { ...state };
    case ADD_DEPOT_DETAILS_SUCCESS:
      return { ...state, addCpDetails: action.data };
    case DELETE_DEPOT_DETAILS:
      return { ...state };
    case DELETE_DEPOT_DETAILS_SUCCESS:
      return { ...state, deleteCpDetails: action.data };
    default:
      return state;
  }
}

export function getColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: UMS_USER_LIST_COLUMNS });
    return api
      .post(
        'ums',
        formatParams(
          { type: 'AG_GRID_COLUMNS', view: 'USER_DETAILS' },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          dispatch({ type: UMS_USER_LIST_COLUMNS_SUCCESS, payload: data });
        } else {
          dispatch({
            type: UMS_USER_LIST_COLUMNS_FAIL,
            payload: 'Something went wrong! Please try again.',
          });
        }
      });
  };
}

export function getRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'USER_DETAILS',
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (Object.keys(data).length) {
            params.success({
              rowData: data.rowData,
              rowCount: getLastRowIndex(params.request, data.rowData),
            });
            const endRow =
              params.request.endRow > data.lastRow
                ? data.lastRow
                : params.request.endRow;
            store.dispatch({
              type: UMS_USER_LIST_ROWS_INFO_SUCCESS,
              payload: { lastRow: data.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

export function fetchDropdownData() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: CREATE_USER_DROPDOWN_DATA });
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'CREATE_USER_DROPDOWN_DATA',
            },
            getState
          )
        )
        .then((response) => {
          const { data } = response;

          if (data) {
            dispatch({
              type: CREATE_USER_DROPDOWN_DATA_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: CREATE_USER_DROPDOWN_DATA_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return response;
        });
    }
  };
}

export function add(formattedValues) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: ADD_UMS_USER });
    let userDetails = [];
    userDetails.push({
      user_email: formattedValues.email,
      first_name: formattedValues.first_name,
      last_name: formattedValues.last_name,
      user_name: formattedValues.user_name,
      user_country_code: formattedValues.user_country_code,
      user_mobile_number: formattedValues.user_mobile_number,
      UserDetailsArray: formattedValues.userDetailsArray,
    });

    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'CDS_ROLES',
            type: 'ADD_USER',
            uuid: getUUID(),
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
            customer: 'SANM',
          },
          UserFormArray: userDetails,
        },
      })
      .then((response) => {
        const data = response.data.returnMsg;
        if (data) {
          dispatch(update(data));
        }
        return response;
      });
  };
  function update(data) {
    return { type: ADD_UMS_USER_SUCCESS, data };
  }
}

export function updateUserDetails(formattedValues) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: UMS_USER_UPDATE });
    let userDetails = [];

    userDetails.push({
      user_email: formattedValues.email,
      first_name: formattedValues.first_name,
      last_name: formattedValues.last_name,
      user_name: formattedValues.user_name,
      user_country_code: formattedValues.user_country_code,
      user_mobile_number: formattedValues.user_mobile_number,
      UserDetailsArray: formattedValues.userDetailsArray,
    });
    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'CDS_ROLES',
            type: 'EDIT_USER',
            uuid: getUUID(),
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
            customer: 'SANM',
          },
          UserFormArray: userDetails,
        },
      })
      .then((response) => {
        let data = response.data.result;
        if (data) {
          if (
            response.statusCode !== 200 &&
            response.data.returnMsg !== 'SUCCESS'
          ) {
            dispatch(update());
            message.error('Something went Wrong!!');
          } else {
            message.success('User Updated Successfully');
            dispatch(update());
          }
        } else {
          console.error();
        }
        return response;
      });
  };
  function update() {
    return { type: UMS_USER_UPDATE_SUCCESS };
  }
}

export function userNameFetch(email) {
  return (dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'LDAP_USERNAME_CHECK',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const data = response.data.username;
        if (data) {
          dispatch(update(data));
        }
        return response;
      });
  };
  function update(data) {
    return { type: GET_UMS_USER_SUCCESS, data };
  }
}

export function getUserDetails(email) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: UMS_USER_DETAIL });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'VIEW_SPECIFIC_USER',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const data = response.data.result.userdetails[0];
        if (data) {
          dispatch(update(data));
        }
        return response;
      });
  };
  function update(data) {
    return { type: UMS_USER_DETAIL_SUCCESS, data };
  }
}

export function setUserStatus(email, enableUser) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: USER_STATUS_UPDATE });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: enableUser ? 'ENABLE_USER_REQUEST' : 'DISABLE_USER_REQUEST',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.returnCode == 0) {
          const {
            result: { userStatus },
          } = data;
          if (userStatus !== null) {
            message.success(userStatus);
            dispatch({
              type: USER_STATUS_UPDATE_SUCCESS,
              payload: enableUser ? 't' : 'f',
            });
          }
        } else {
          message.error('Error in updating user status. Please try again!');
        }
        return response;
      });
  };
}

export function resetUserPassword(email) {
  return (_dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'RESET_USER_PASSWORD_REQUEST',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        const { resetUserPasswordStatus } = data.result;
        if (data.returnCode == '0') {
          if (resetUserPasswordStatus) {
            message.success(resetUserPasswordStatus);
          }
        } else {
          message.error(extractException(data.result.errorMessage));
        }
        return response;
      });
  };
}

export function deleteUser(formattedValues) {
  return (_dispatch, _getState, { api }) => {
    let userDetails = [];
    userDetails.push({
      user_email: formattedValues.userDetailsArray[0].user_email,
      UserDetailsArray: formattedValues.userDetailsArray,
    });

    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'CDS_ROLES',
            type: 'REMOVE_USER',
            uuid: getUUID(),
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          UserFormArray: userDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const { returnMsg } = data;
        if (data.returnCode == '0') {
          if (returnMsg === 'SUCCESS') {
            message.success('User deleted successfully');
          }
        } else {
          message.error(extractException(data.result.errorMessage));
        }
      });
  };
}

export function resetUser(email) {
  return (_dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'RESET_USER_REQUEST',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.returnCode == '0') {
          message.success('User reset success');
        } else {
          message.error('Error in resetting user. Please try again');
        }
        return response;
      });
  };
}

export function resendTempPasswd(email) {
  return (_dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'RESEND_TEMP_PASSWORD_REQUEST',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.returnCode == '0') {
          message.success('Temp password has been resent successfully.');
        } else {
          message.error('Error in resending temp password. Please try again');
        }
        return response;
      });
  };
}

export function cpDetailsAdd(formattedValues) {
  return (dispatch) => {
    dispatch({ type: ADD_DEPOT_DETAILS });
    dispatch(update(formattedValues));
    return true;
  };
  function update(data) {
    return { type: ADD_DEPOT_DETAILS_SUCCESS, data };
  }
}

export function cpDetailsDelete(formattedValues) {
  return (dispatch) => {
    dispatch({ type: DELETE_DEPOT_DETAILS });
    dispatch(update(formattedValues));
    return true;
  };
  function update(data) {
    return { type: DELETE_DEPOT_DETAILS_SUCCESS, data };
  }
}
